@import "../../../assets/scss/variables";

.ProgressBar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  vertical-align: top;
  width: 100%;

  &::-webkit-progress-bar {
    background-color: rgba(243, 165, 67, 0.45);
    height: 1px;
  }

  &::-webkit-progress-value {
    background-color: $primary-color;
    height: 3px;
    position: relative;
    top: -1px;
  }

  &[value]  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    color: $primary-color;
    height: 3px;
    position: relative;
    top: -1px;
  }

  &.secondaryColor {
    &::-webkit-progress-bar {
      background-color: $lightgray;
    }
  }

  &.label {
    &[value]::after {
      color: $text_primary_light_color;
      content: attr(data-name);
    }
  }
}
