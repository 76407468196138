@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.TableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 610px;

  &.inactive {
    display: none;
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .pagination {
      display: flex;
      justify-content: flex-end;

      .Button {
        background: #00059e;
        color: white;
        font-family: $roboto_light;
        font-size: pxToRem(15px);
        height: 30px;
        margin-left: 15px;
        padding: 5px;
        width: 30px;

        &:first-child,
        &:last-child {
          padding: 5px 15px;
          width: max-content;
        }

        &.active {
          background-color: #0006a2;
          border-color: #0006a2;
        }
      }

      span {
        align-items: flex-end;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-left: 15px;
        width: 30px;
      }
    }
  }
}
