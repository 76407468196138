@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Investments {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding-bottom: 100px;
  width: 100%;
  padding: 40px 70px 100px;
  box-sizing: border-box;

  @include breakpoint(desktop-small) {
    margin: 60px 0 0;
    padding: 0 91px 150px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 140px 150px;
  }

  .TrendingTopics {
    box-sizing: border-box;
    margin-top: 30px;
    // padding: 0 40px;
    position: initial;
  }

  .info-investments {
    padding: 60px 0;

    h2 {
      font-size: 50px;
      font-family: $roboto_light;
    }

    .investments {
      color: white;
      font-family: $roboto_light;
      font-size: 15px;
    }
  }

  .container-cards-investments {
    box-sizing: border-box;
    display: flex;
    gap: 30px;
    height: 464px;
    min-width: 100%;
    width: 100%;

    .card-alocation {
      height: 100%;
      width: 50%;

      .title {
        font-family: $roboto_light;
        font-size: 15px;
        color: #cccfff;
      }

      .bg {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
      }
    }

    .values-investments {
      display: flex;
      flex-direction: column;
      gap: 30px 0;
      height: 100%;
      justify-content: space-between;
      width: 50%;

      .Card {
        height: 100%;
        width: 100%;

        .title {
          font-family: $roboto_light;
          font-size: 15px;
          color: #cccfff;
        }

        .bg {
          background-color: #3d46db;
          mix-blend-mode: overlay;
          opacity: 0.37;
        }

        .content {
          height: 80%;
          justify-content: space-between;

          article {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;

            p {
              font-family: $primary_font;
              font-size: $xl_base_font_size;
              max-width: 70%;

              &.grade {
                font-size: $lg_title_font_size;
              }
            }
          }
        }

        .link-style {
          position: absolute;
          right: 0;
          text-transform: unset;
          top: 10px;
        }
      }
    }
  }

  .table-investments {
    margin-top: 70px;

    .container-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;

      button {
        background: rgba(22, 72, 159, 0.4);
        border: 1px solid rgba(22, 72, 159, 0.4);
        color: white;
        cursor: pointer;
      }
    }

    .infos-table {
      h4 {
        font-family: $roboto_light;
        font-size: 35px;
        margin: 0;
      }

      .number-investments {
        font-family: $roboto_light;
        font-size: 20px;
        font-weight: 400;
        margin: 5px 0 40px 0;
      }
    }

    .button-create-investment {
      background-blend-mode: overlay;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
      width: 100%;

      .Button {
        border: unset;
        color: white;
        cursor: pointer;
        font-family: $roboto_regular;
        position: relative;

        &::before, &::after  {
          background-color: #3D46DB;
          mix-blend-mode: overlay;
          opacity: 0.87;
          pointer-events: none;
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}
