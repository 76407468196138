@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.PopUp {
  align-items: center;
  background-color: $background_primary_dark_color;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &.active {
    display: flex;
  }

  .pop-up {
    background: $background_primary_dark_color;
    border-bottom: 2px solid $secondary-color;
    border-top: 2px solid $secondary-color;
    box-shadow: 0 3px 20px #00000057;
    display: flex;
    height: 350px;
    justify-content: center;
    width: 100%;
  }
}
