@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.InvestmentResume {
  .select-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 15px;

    .Select {
      margin: 10px 30px;

      select {
        color: $text_secondary_color;
        height: 48px;
      }
    }
  }

  .resume-tab-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 20px 0;

    .box {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .title-chart {
        font-family: $roboto_thin;
        font-size: 25px;

        span {
          font-size: 25px;
          font-family: $roboto_regular;   
        }
      }

      .chart {
        height: 100%;
        width: 90%;
        margin: 40px 0;

        table {
          margin-top: -5px;
        }

        .line-chart {
          height: 100%;
          width: 100%;
        }
      }
    }

    .average-grade {
      position: relative;
      height: 120px;
      width: 138px;
      color: #ffffff;
      z-index: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;

      .title-average-grade {
        font-family: $roboto_thin !important;
        font-size: 16px;
        margin-bottom: 10px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0b16e0;
        mix-blend-mode: overlay;
        opacity: 0.1;
        z-index: -1;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      margin: 40px 0;
      padding: 0 15px;
      gap: 11px;

      .Grade {
        font-size: $xxs_title_font_size;
        margin-bottom: 10px;

        svg {
          height: 20px;
          margin-left: 10px;
          width: 20px;
        }
      }

      .investing-community {
        color: $text_primary_light_color;
        font-family: $primary-font, sans-serif;
        font-size: $xs_title_font_size;
        margin: 10px 0;
        word-wrap: break-word;
      }

      .investing-description {
        color: $table_header_color;
        font-family: $primary-font, sans-serif;
        font-size: $sm_base_font_size;
        width: 90%;
      }

      .details {
        display: flex;
        gap: 0 13px;
        margin: 35px 0;
        width: 90%;

        span {
          font-family: $primary-font, sans-serif;
          font-size: $base_font_size;
          text-decoration: underline;
        }
      }

      .button-new {
        right: 100px;
        bottom: 0;
        height: 47px;
        min-width: 100px;
        display: flex;
        background-color: rgba(3, 10, 158, 0.695);
        box-sizing: border-box;
        font-family: $roboto_light;
        font-size: 17px;
        border: none;
        gap: 20px;

        .fa6-solid--arrow-right {
          display: inline-block;
          width: 23px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
        }

        @include breakpoint(lg) {
          margin-left: 20px;
          margin-left: unset;
          width: max-content;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .edit-investment {
        text-decoration: underline;
        font-size: 20px;
        font-family: $primary-font;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}
