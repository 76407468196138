@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.CompanyAbilitiesTable {
  border-collapse: collapse;
  text-align: left;
  width: 50%;

  th,
  td {
    font-weight: 400;
    padding: 5px;
    font-family: $roboto_regular;

    &:nth-child(2) {
      padding-left: 15px;
      width: 176px;

      .Grade {
        font-size: 20px !important;
        width: 50%;

        svg {
          display: none;

          width: 14px;
          height: 14px;
        }
      }
    }

    &:nth-child(3) {
      width: 120px;
      font-size: 20px;
    }

    &:last-child {
      width: 20px;
      padding-right: 20px;

      .fa6-solid--arrow-right {
        display: inline-block;
        width: 23px;
        height: 20px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
      }
    }
  }

  th {
    font-size: pxToRem(20px);
    font-family: $roboto_light;
    white-space: nowrap;
    color: #cccfff;
  }

  tr {
    cursor: pointer;
    display: inline-table;
    padding: 0 15px;
    width: 100%;
  }

  thead {
    tr {
      width: calc(100% - 10px);
    }
  }

  tbody {
    display: block;
    height: calc(100vh - 447px);
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 4, 111, 0.1);
      mix-blend-mode: overlay;
      // border: 2px solid rgba(120, 120, 120, 0.3);
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0005a24b;
    }

    tr {
      height: 55px;
      // opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      &.active {
        background-color: #0006a2;
        opacity: 1;
      }

      td {
        &:nth-child(3) {
          p {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0;
            width: 43px;
          }
        }
      }
    }
  }
}
