@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.DragAndDropUpload {
  align-items: center;
  border: 1px dashed rgba(255, 255, 255, 0.45);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;
  max-width: 1000px;
  padding: 50px;

  svg {
    margin-right: 20px;
  }
}
