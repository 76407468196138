@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.AddManagers {
  width: 100%;

  h2 {
    font-family: $roboto_light;
    font-size: 40px;
    margin: 50px 0 20px;
  }

  p {
    font-family: $roboto_light;
    margin: 30px 0 50px 0;

    &.feedback-message {
      color: $red_variation;
      margin: 0;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    &.sub {
      &.one {
        margin-top: 50px;
      }

      margin: 20px 0;
      color: rgba(255, 255, 255, 0.40);
    }
  }

  label {
    font-family: $roboto_light;
  }

  .top-info, .top-info div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    img {
      height: 50px;
      margin-right: 20px;
      width: 50px;
    }
  }

  .Input {
    margin-bottom: 30px;

    p {
      margin: 0 0 5px;
    }

    input {
      &::placeholder {
        color: $darkgray;
        color: rgba(255, 255, 255, 0.50);
      }

      &:focus {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }

  .add-another-manager {
    background-color: transparent;
    border: 3px solid transparent;
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;
    font-family: $roboto_light;
    font-size: 20px;
    margin: 0 0 30px;
    outline: none;

    &:focus {
      border-bottom-color: $border_color;
    }

    p {
      margin: 0;
      border-bottom: 1px solid white;
    }

    span {
      margin-right: 10px;

      &.ion--add-sharp {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='white' stroke-linecap='square' stroke-linejoin='round' stroke-width='32' d='M256 112v288m144-144H112'/%3E%3C/svg%3E");
      } 
    }
  }

  .Button {
    align-items: center;
    background-color: #0005B2;
    border: unset;
    display: flex;
    font-family: $roboto_light;
    font-size: 20px;

    &:hover {
      filter: brightness(0.9);
    }

    .ph--arrow-right-bold {
      display: inline-block;
      margin-left: 30px;
      width: 25px;
      margin: 0 0 0 30px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='m224.49 136.49l-72 72a12 12 0 0 1-17-17L187 140H40a12 12 0 0 1 0-24h147l-51.49-51.52a12 12 0 0 1 17-17l72 72a12 12 0 0 1-.02 17.01'/%3E%3C/svg%3E");
    }
  }
}
