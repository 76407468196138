@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/variables";

.ModalInvestor {
  // background: url("../../../assets/images/background.png") 100%;
  background: url("../../../assets/images/bg-mobile.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 50%;
  margin: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 20;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  .Loading {
    top: -280px;
  }

  .header {
    display: flex;
    height: 30%;
    justify-content: space-between;
    position: fixed;
    width: 100%;

    > span:last-child {
      color: $yellow;
      cursor: pointer;
      font-family: $primary_font;
      font-size: $sm_base_font_size;
      height: 35px;
      margin: 30px 50px 0 0;
      text-transform: uppercase;
    }

    .container-investor {
      margin: 50px 0 0 50px;

      p {
        color: white;
        font-size: $lg_base_font_size;
      }

      .info-investor {
        align-items: center;
        display: flex;
        margin-top: 20px;

        p {
          color: white;
          font-family: $secondary_font;
          font-size: $sm_title_font_size;

          &:nth-child(2),
          &:nth-child(3) {
            color: $text_secondary_color;
            font-family: $primary-font;
            font-size: $xxl_base_font_size;
            margin: 15px 0 0 20px;
          }
        }
      }

      .nav-modal {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-top: 50px;
        gap: 0 30px;

        .TabButton {
          // margin-left: 80px;

          button {
            font-family: $roboto_light;
            font-size: 24px;
          }

          &.active {
            border-bottom-color: orange;
          }

          &:first-child {
            margin: 0;
          }

          &.contributions {
            border-bottom-color: #ffc400;
            color: $white;

            button {
              font-family: $roboto_bold;
            }
          }

          &.resume {
            border-bottom-color: #ffc400;
            color: $white;

            button {
              font-family: $roboto_bold;
            }
          }

          &.investments {
            border-bottom-color: #ffc400;
            color: $white;

            button {
              font-family: $roboto_bold;
            }
          }
        }
      }
    }
  }

  .render-section {
    padding: 0 50px;
    box-sizing: border-box;
  }

  section {
    background-color: rgba(29, 29, 48, 0.5);
    // height: 100%;
    margin-top: 260px;
    // margin: 249px 50px 0;
    // width: 100%;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid rgba(120, 120, 120, 0.3);
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $transparent_state_hover_color;
    }
  }
}
