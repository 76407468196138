@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.Departments {
  height: calc(100vh - 267px);
  min-height: calc(100vh - 267px);
  width: 48%;

  h2 {
    color: $text_primary_light_color;
    font-family: $secondary_font;
    font-size: $lg_base_font_size;
    text-transform: uppercase;
  }

  .container-dpt {
    height: calc(100% - 56px);
    width: 100%;

    .wrapper-dpt {
      height: calc(100% - 50px);
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border: 1px solid rgba(120, 120, 120, 0.3);
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $white;
      }
    }

    .create-dpt {
      margin-top: -30px;
      max-width: 290px;
    }
  }
}
