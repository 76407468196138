@import "../../../assets/scss/variables";

.Loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 99999;

  &.visible {
    display: flex;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;

    & > div {
      height: 100px;
      margin-top: 48px;
      position: relative;
      width: 115px;

      div {
        animation: profile 1s infinite;
        box-sizing: border-box;
        background-color: transparent;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom: 40px solid $white;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        position: absolute;
        right: 50px;
        top: 50px;
        transform-origin: top center;
        width: 5px;

        @for $n from 1 to 21 {
          &:nth-child(#{$n}) {
            animation-delay: $n * 0.05s;
            transform: rotate($n * 18deg) translateY(10px);
          }
        }


      }
    }

    p {
      color: $white;
      font-size: $title_size;
      font-weight: 200;
      margin: 40px 0 0;
      text-align: center;
      text-transform: lowercase;
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes profile {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
