@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.ModalInvestments {
  .investments {
    background-color: transparent;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0b16e0;
      mix-blend-mode: overlay;
      opacity: 0.1;
      z-index: -1;
    }

    border-collapse: collapse;
    box-sizing: border-box;
    min-height: calc(100vh - 631px);
    padding: 20px;
    width: 100%;

    .investments-count {
      padding: 30px;

      h1 {
        font-size: $xs_title_font_size;
        margin-bottom: 10px;
      }
    }

    // table {
    //   border-collapse: collapse;
    //   // border-spacing: 0;
    //   width: 100%;

    //   thead {
    //     display: table-header-group;

    //     th {
    //       color: white;
    //       text-transform: uppercase;
    //       padding: 20px 15px;
    //       background-color: #0b16e0;
    //     }
    //   }

    //   tr {
    //     margin-bottom: 15px !important;
    //     background-color: rgba(61, 70, 219, 0.28);
    //   }

    //   th,
    //   td {
    //     text-align: left;
    //     padding: 15px;
    //     vertical-align: top;
    //   }

    //   tbody {
    //     box-sizing: border-box;
    //     display: block;
    //     max-height: 450px;
    //     max-width: 100%;
    //     min-height: 300px;
    //     overflow-y: auto;
    //     padding-right: 10px;
    //     width: 100%;

    //     tr {
    //       display: table;
    //       // font-size: 13px;
    //       // background-color: rgba(61, 70, 219, 0.28);
    //       // box-sizing: border-box;
    //       // margin-bottom: 15px !important;
    //       // width: 100%;
    //     }

    //     &::-webkit-scrollbar-track {
    //       background-color: transparent;
    //       border: 1px solid rgba(120, 120, 120, 0.3);
    //       height: 100vh;
    //     }

    //     &::-webkit-scrollbar {
    //       height: 100vh;
    //       width: 5px;
    //     }

    //     &::-webkit-scrollbar-thumb {
    //       background-color: $white;
    //     }

    //     @include breakpoint(desktop-small) {
    //       &::-webkit-scrollbar {
    //         width: 10px;
    //       }
    //     }
    //   }

    //   tr {
    //     height: 55px;
    //     width: 100%;
    //     font-size: 13px;
    //     background-color: rgba(61, 70, 219, 0.28);
    //     box-sizing: border-box;
    //     margin-bottom: 15px !important;
    //   }
    // }

    table.investors-table {
      // background-color: rgba($color: $black, $alpha: 0.5);
      background-color: transparent;
      width: 100%;

      thead {
        margin: 0;

        tr {
          margin: 0 !important;
          background-color: #0006a2;
        }
      }

      tr th,
      tr td {
        padding: 25px 15px 25px 30px;
      }

      tr th {
        font-size: 15px;
        font-family: $roboto_light;
      }

      tr {
        font-size: 13px;
        // background-color: rgba(61, 70, 219, 0.2);
        box-sizing: border-box;
        margin-bottom: 15px !important;
        position: relative  ;
        color: white;
        background-color: #0b16e05a;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   background-color: #0b16e0;
        //   mix-blend-mode: overlay;
        //   opacity: 0.1;
        //   z-index: -1;
        // }
      }

      tr th,
      tr td {
        width: 10%;
      }

      tr td button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: $roboto_light;
        padding: 15px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.4s;

        &::before,
        &::after {
          background-color: #3d46db;
          mix-blend-mode: overlay;
          opacity: 0.37;
          pointer-events: none;
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }

        &:hover {
          background-color: $secondary-color;
        }
      }
    }

    .reassess {
      background-color: $transparent_state_hover_color;
      border: none;
      height: 30px;
    }
  }
}
