@import "../../../assets/scss/variables";

.SearchBar {
  align-items: center;
  border: none;
  border-bottom: 2px solid $border_color;
  display: flex;
  height: 35px;
  justify-content: space-between;
  max-width: 450px;
  width: 100%;

  & > input {
    background-color: transparent;
    border: none;
    color: $placeholder_color;
    height: 30px;
    outline: none;
    padding: 0 10px;
    width: 90%;
  }

  & > svg {
    fill: #707070;
    width: 30px;
  }
}
