@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";

.InvitationByCsv {
  background-color: $background_primary_dark_color;
  display: none;
  min-height: 610px;

  &.active {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container {

    h2 {
      color: $text_primary_light_color;
      font-family: $primary_font;
      font-size: pxToRem($title_size);
      font-weight: 500;
    }

    p {
      color: $darkgray_topic;
      font-family: $primary_font;
      font-size: pxToRem($subtitle_size);

      span {
        color: $text_primary_light_color;
        cursor: pointer;
        font-family: $primary_font;
        font-size: pxToRem($subtitle_size);
        font-weight: 500;
      }
    }

    .DragAndDropUpload {
      svg {
        height: 72px;

        .cls-1 {
          fill: $white;
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;

      .Button {
        margin-right: 15px;

        &.inactive {
          border-color: $border_color;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .cancel-button {
        outline: none;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $text_primary_light_color;
        color: $text_primary_light_color;
        cursor: pointer;
        font-family: $primary_font;
        font-size: pxToRem($reading_size);
        height: 40px;
      }
    }
  }

}
