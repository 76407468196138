@import "../../../assets/scss/mixins";

.RecoveryCode {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(md) {
    width: 300px;
  }

  @include breakpoint(lg) {
    width: 500px;
  }

  p {
    color: $white;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
  }

  .code-container {
    display: flex;
    gap: 8px;
    margin: 50px 0 100px;

    .otp-field {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $border_color;
      height: 44px;
      padding-left: 7px;
      padding-right: 5px;
      text-align: center;
      width: 36px;

      @include breakpoint (md) {
        width: 46px;
      }
    }

    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;

    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .Button.link-style {
    text-transform: unset;
  }
}
