@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Navbar {
  align-items: flex-end;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 35px 26px;
  position: fixed;
  width: 100%;
  z-index: 5;

  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  @include breakpoint(desktop-small) {
    bottom: unset;
    height: 60px;
    padding: 50px;
    top: 0;
  }

  & > div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(desktop-small) {
      &:first-child {
        display: flex;
        gap: 50px;
        height: 90%;
        width: min-content;
      }

      &:last-child {
        width: 120px;
      }
    }

    &:nth-child(2) {
      & > button:hover {
        border-bottom-color: transparent;
      }
    }

    & > button {
      align-items: center;
      background-color: $black;
      border: none;
      box-sizing: border-box;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      font-family: $roboto_light;
      font-size: $lg_base_font_size;
      height: 40px;
      justify-content: center;
      outline: none;
      padding: 0;
      position: relative;
      text-transform: lowercase;

      &:nth-child(6) {
        display: none;
      }
      &:last-child {
        display: none;

        @include breakpoint(desktop-small) {
          display: flex;
        }
      }

      @include breakpoint(desktop-small) {
        background-color: inherit;
        border-bottom: 3px solid transparent;
        color: $white;
        flex-direction: row;
        font-size: 16px;
        text-transform: capitalize;
      }

      &.active {
        font-family: $roboto_bold;

        @include breakpoint(desktop-small) {
          border-color: #ffc400;
          border-top-color: transparent;
        }
      }

      &:hover {
        ul {
          display: block;
        }
      }

      &.logo:hover {
        border-bottom-color: transparent;
      }

      &.notification,
      &.logout {
        height: 90%;
        padding: 5px 0;

        svg {
          height: 25px;
          margin-left: 10px;
          width: 25px;

          path {
            fill: white;
          }
        }
      }

      &.notification {
        position: relative;

        &:hover {
          color: $white;
        }
      }

      .text-admin {
        font-family: $secondary_font;
        margin: 0 0 0 15px;
        font-size: 20px;
        font-style: italic;
      }

      &.logo {
        margin-right: -18px;
      }

      ul {
        background-color: $background_primary_light_color;
        display: none;
        list-style-type: none;
        margin: 0;
        padding: 10px;
        position: absolute;
        top: 35px;

        @include breakpoint(lg) {
          bottom: 35px;
        }

        button {
          border: unset;
          border-bottom: 3px solid transparent;
          color: $black;
          height: 40px;
          font-size: 16px;

          &.active,
          &:hover {
            border-bottom-color: $black;
          }
        }
      }
    }
  }

  button {
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    text-transform: capitalize;

    &.logout {
      svg {
        margin-left: 10px;
      }
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
}
