@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.Questions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  h1 {
    color: $white;
    font-family: $primary_font;
    font-size: $title-size;
    font-weight: 600;
    margin: 15px 0 116px 0;
  }

  summary {
    outline: none;
  }

  .questions {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
}
