@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.CreateTask {
  align-items: flex-start;
  background: #0000003b 0 0 no-repeat padding-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 5%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  &.active {
    display: flex;
  }

  .container-create {
    background: #172f6c;
    box-sizing: border-box;
    max-width: 1093px;
    min-height: 82%;
    padding-left: 72px;
    position: relative;
    width: 100%;

    .close-modal {
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      span {
        color: $yellow;
        cursor: pointer;
        font-family: $primary_font;
        font-size: $sm_base_font_size;
        height: 35px;
        margin: 0;
        text-transform: uppercase;
      }
    }

    .title-create {
      color: $white;
      font-family: $secondary_font;
      font-size: 34px;
    }

    .form-create {
      display: flex;
      flex-direction: column;
      gap: 16px 0;
      margin-top: 40px;
      padding-right: 30px;

      .Button {
        background: rgba(22, 72, 159, 0.4);
        border: unset;
        cursor: pointer;
        font-family: $primary_font;

        &:hover {
          filter: brightness(0.8);
        }
      }

      .container-button {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-left: 30px;
        width: 100%;

        .message-error {
          color: red;
          display: none;
          margin-right: 30px;

          &.active {
            display: flex;
          }
        }
      }

      .box-input {
        align-items: center;
        display: flex;

        .container-radio {
          display: flex;
          flex-wrap: wrap;
          max-width: 600px;

          label {
            text-align: left;
            margin: 0;
          }

          // label {
          //   text-align: left;
          //   margin: 0;
          // }
        }

        &.description {
          align-items: flex-start;

          textarea {
            background-color: #15378e;
            border: none;
            box-sizing: border-box;
            padding: 0 10px;
            resize: none;
          }
        }

        p {
          font-family: $secondary_font;
          font-size: 22px;

          &.generate-code {
            text-align: center;
            width: 310px;
          }
        }

        label {
          font-family: $primary_font;
          font-size: 22px;
          margin-right: 34px;
          text-align: right;
          width: 170px;
        }

        input {
          background-color: #15378e;
          border: none;
          box-sizing: border-box;
          color: $white;
          font-size: 22px;
          height: 46px;
          padding: 0 10px;
          width: 353px;
        }

        &.investment {
          min-height: 458px;

          label {
            font-family: $primary_font;
            font-size: 22px;
            margin-right: 34px;
            text-align: right;
            width: max-content !important;
          }

          input {
            background-color: #15378e;
            border: none;
            box-sizing: border-box;
            color: $white;
            font-size: 22px;
            height: 46px;
            padding: 0 10px;
            width: 50% !important;
          }
        }

        &.select {
          min-height: 458px;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px 0;

          label {
            text-align: left;
          }

          .multi-select {
            display: flex;
            align-items: flex-start;
            justify-content: space-between !important;
            width: 100%;

            .MultiSelect {
              .container-select {
                .box-select {
                  width: 440px;
                }
              }

              .modal-select {
                width: 440px;
              }
            }
          }
        }

        .Select {
          div {
            align-items: center;
            flex-direction: row;

            select {
              height: 46px;
              min-width: 272px !important;
            }
          }
        }
      }

      .box-checks {
        display: flex;
        margin-top: 20px;

        .title-checks {
          font-family: $primary_font;
          font-size: 22px;
          margin-right: 34px;
          text-align: right;
          width: 170px;
        }

        .container-checks {
          display: flex;
          gap: 0 20px;

          label {
            font-family: $secondary_font;
            font-size: 22px;
          }
          .radio-item {
            display: inline-block;
            position: relative;
            padding: 0 6px;
            // margin: 10px 0 0;
          }

          .radio-item input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

          .radio-item label {
            // color: #666;
            font-weight: normal;
          }

          .radio-item > label:before {
            content: " ";
            display: inline-block;
            position: relative;
            top: 5px;
            margin: 0 5px 0 0;
            width: 20px;
            height: 20px;
            border-radius: 11px;
            border: 2px solid #ffa500;
            background-color: transparent;
          }

          .radio-item input[type="radio"]:checked + label:after {
            border-radius: 11px;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 9px;
            left: 10px;
            content: " ";
            display: block;
            background: #ffa500;
          }
        }
      }
    }
  }
}
