@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.Card {
  box-sizing: border-box;
  height: max-content;
  padding: 10px;
  position: relative;

  @include breakpoint(tablet-portrait) {
    padding: 20px;
  }

  .bg {
    background: #0b16e01f;
    background-blend-mode: overlay;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &.container {
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    width: 100%;

    h3, .subtitle {
      font-family: $secondary_font;
      font-size: $sm_title_font_size;
    }
  }

  .content {
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    padding: 10px;
    z-index: 2;

    .title {
      color: $table_header_color;
      font-family: $secondary_font;
      font-size: $lg_base_font_size;
      letter-spacing: 1px;
      margin-top: 0;
      width: 100%;
    }
  }
}
