@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.EditManager {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 29px 0 27px;
  width: 31%;

  .BackButton {
    margin-bottom: 50px;
  }

  h1 {
    color: $text_primary_light_color;
    font-family: $secondary_font;
    font-size: $xs_title_font_size;
    font-weight: 600;
    margin: 15px 0 30px;
  }

  div {
    margin-top: -6%;

    h2 {
      color: $text_primary_light_color;
      font-family: $secondary_font;
      font-size: $xxl_base_font_size;
      font-weight: 600;
    }

    h3 {
      font-family: $secondary_font;
      font-size: $lg_base_font_size;
      font-weight: 600;
      margin: 30px 0 10px;
    }

    p {
      font-family: $secondary_font;
      font-size: $base_font_size;
      margin: 0;
    }
  }
}
