body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid rgba(120, 120, 120, 0.3);
    height: 100vh;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 100vh;
    background-color: white
  }

  &::-webkit-scrollbar-thumb {
    background-color: #16489F;
    mix-blend-mode: overlay;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none;
}
