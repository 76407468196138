@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/variables";

.ModalContributionsTableRow {
  border-collapse: collapse;
  box-sizing: border-box;
  min-height: calc(100vh - 460px);
  width: 100%;

  td, th {
    padding: 20px;

    .Button {
      margin: 0;
    }

    &:first-child {
      padding: 20px 30px;
      width: 139px;

      @include breakpoint(desktop-small) {
        width: 195px;
      }
    }

    &:nth-child(3) {
      width: 140px;

      @include breakpoint(mobile-small) {
        width: 160px;
      }

      @include breakpoint(desktop-medium) {
        width: 180px;
      }

      @include breakpoint(desktop-large) {
        width: 200px;
      }
    }

    &:nth-child(4) {
      width: 120px;

      @include breakpoint(desktop-small) {
        width: 140px;
      }

      @include breakpoint(desktop-medium) {
        width: 160px;
      }

      @include breakpoint(desktop-large) {
        width: 180px;
      }
    }

    &:nth-child(5) {
      width: 151px;

      @include breakpoint(desktop-small) {
        width: 171px;
      }

      @include breakpoint(desktop-medium) {
        width: 181px;
      }

      @include breakpoint(desktop-large) {
        width: 201px;
      }
    }

    &:nth-child(6) {
      width: 151px;

      @include breakpoint(desktop-small) {
        width: 171px;
      }

      @include breakpoint(desktop-medium) {
        width: 181px;
      }

      @include breakpoint(desktop-large) {
        width: 240px;
      }
    }

    &:nth-child(7) {
      width: 151px;

      @include breakpoint(desktop-small) {
        width: 171px;
      }

      @include breakpoint(desktop-medium) {
        width: 181px;
      }

      @include breakpoint(desktop-large) {
        width: 240px;
      }
    }

    &:last-child {
      width: 220px;

      @include breakpoint(desktop-small) {
        width: 115px;
      }

      @include breakpoint(desktop-medium) {
        width: 115px;
      }

      @include breakpoint(desktop-large) {
        width: 190px;
      }
    }
  }

  thead {
    tr {
      width: calc(100% - 10px);

      th {
        padding-top: 50px;

        &:first-child {
          padding: 50px 30px 20px;
        }
      }
    }
  }

  tbody {
    max-height: unset;

    tr {
      text-align: left;

      &.no-topics {
        height: 80px;

        td:first-child {
          padding-left: 20px;
        }
      }
    }
  }
}
