@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.CommunityRegister {
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  padding: 0 60px;
  width: 100%;

  @include breakpoint(desktop-medium) {
    padding: 0 100px;
  }

  @include breakpoint(desktop-large) {
    padding: 0 150px;
  }

  .e2w-principles {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 85%;
    justify-content: flex-end;
    width: 490px;

    h2 {
      font-family: $roboto_regular;
      font-size: 49px;
      margin: 50px 0 20px;
    }

    p {
      font-family: $roboto_light;
      font-size: 20px;
      margin: 0;
    }

    .Logo {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 20%;

      svg {
        height: 124px;
        width: 200px;
      }

      div {
        height: 70px;

        h2 {
          font-size: 2rem;
          line-height: 0.8;
        }
      }
    }

    .steps-container {
      left: 150px;
      margin-top: 40%;
      top: 40px;
      width: calc(100% - 200px);

      @include breakpoint(tablet-portrait) {
        position: unset;
        width: 100%;
      }

      div {
        display: flex;
        justify-content: space-between;

        p {
          color: $border_color;
          display: none;
          font-family: $secondary_font;
          margin: 0;

          @include breakpoint(tablet) {
            display: block;
          }

          &.selected {
            color: $text_primary_light_color;
            display: block;
          }
        }
      }

      .ProgressBar {
        margin-top: 50px;
      }
    }
  }

  .Card {
    height: 100%;
    width: 600px;

    .bg {
      background-color: #00067d;
    }

    .content {
      justify-content: flex-start;
      padding: 40px 20px 80px;
    }
  }
}
