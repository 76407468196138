@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Summary {
  .welcome-text {
    margin-bottom: 90px;

    p {
      font-family: $roboto_light;
      font-size: 18px;
      margin: 10px 0;

      &.subtitle {
        font-size: 25px;
      }
    }
  }

  .container-infos {
    display: flex;
    width: 100%;
    gap: 20px;

    .container-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between;
    }

    .top-block,
    .bottom-block {
      display: flex;
      gap: 20px;
      width: 100%;
      height: 100%;
    }

    .Card {
      min-height: 170px;
      height: 100%;

      .bg {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
      }

      .title {
        font-family: $roboto_light;
        font-size: 15px;
        color: #cccfff;
      }

      .link-style {
        position: absolute;
        right: 0;
        text-transform: unset;
        top: 20px;
        font-family: $roboto_regular;
        font-size: 15px;
      }

      &.card-metrics {
        width: 100%;
        max-width: 500px;

        .chart {
          margin: 0;
          svg {
            // max-height: 332px;
            width: 300px;
          }
        }
      }

      &.card-engagement {
        width: 100%;
        min-width: 250px;
        // max-height: 214px;
        // height: 219px;

        article {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: flex-end;

          .active-investidores {
            display: flex;
            align-items: center;
            gap: 15px;

            span {
              font-family: $roboto_regular;
              font-size: 38px;

              @include breakpoint(desktop-medium) {
                font-size: 46px;
              }
            }

            p {
              font-family: $roboto_regular;
              font-size: 18px;
            }
          }

          .infos-engagement {
            display: flex;
            flex-direction: column;

            p {
              font-family: $roboto_regular;
              font-size: 18px;
            }
          }
        }
      }

      &.card-info {
        width: 30%;
        min-width: 234px;

        .bg {
          background-color: #3d46db;
          opacity: 1;
        }

        .link-style {
          top: 20px;
        }

        article {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: flex-end;

          p {
            font-family: $roboto_regular;
            font-size: 20px;
          }
        }
      }

      &.card-high {
        width: 100%;
        // min-width: 250px;
        // height: 219px;

        article {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          min-height: 50px;
          max-height: 83px;
          align-items: flex-end;

          p {
            font-family: $roboto_regular;
            font-size: 20px;
            max-width: 170px;

            @include breakpoint(desktop-large) {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  .data-summary-cards {
    display: flex;
    justify-content: space-between;

    .Card {
      height: 180px;

      &:first-child {
        width: 25%;
      }

      &:nth-child(2) {
        width: 48%;
      }

      &:last-child {
        width: 25%;
      }

      .contact-button {
        font-size: $base_font_size;
        margin-top: 3px;
      }

      div {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        width: 100%;

        article {
          display: flex;
          width: 100%;

          > span {
            margin-top: 35px;
            width: 100%;
          }

          &.grade {
            flex-direction: column;
          }

          div {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:first-child {
              p:first-child {
                font-size: $lg_title_font_size;
              }
            }

            &:last-child {
              align-items: flex-end;

              p {
                text-align: right;
              }
            }
          }
        }
      }

      &:last-child {
        min-width: 320px;
      }

      .link-style {
        position: absolute;
        right: 0;
        text-transform: unset;
        top: 10px;
      }

      .Grade {
        font-size: $lg_title_font_size;
      }
    }
  }
}
