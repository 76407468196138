@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/variables";

.InvestorSummary {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 50px 60px;

  .left-summary,
  .right-summary {
    margin-bottom: 50px;
    width: 100%;

    .cards-container {
      display: grid;
      margin-top: 20px;

      .biggest-investors,
      .smallest-investors {
        margin-left: 20px;
      }

      .active-investors-content {
        margin: 20px 0;
        width: 100%;

        p {
          margin: 5px 0;
        }
      }
    }
  }

  .left-summary {

    .profile {
      grid-template-columns: 1fr 1fr;

      .card-summary-wallet {
        margin-top: 20px;
        height: 554px;

        p {
          height: 15px;
          margin-top: 50px;
          text-align: center;
        }

        p.title {
          font-size: $lg_title_font_size;
          margin-top: 0;
          text-align: left;
        }

        .chart {
          margin: 32px;
          div.error-message p {
            margin-left: 50px;
          }
        }
      }
    }
  }

  .right-summary {
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    .profile {
      grid-template-columns: 1fr 1fr;

      .card-profile-contributions {
        margin-top: 20px;
        height: 554px;

        p {
          height: 15px;
          margin-top: 50px;
          text-align: center;
        }

        p.title {
          font-size: $lg_title_font_size;
          margin-top: 0;
          text-align: left;
        }

        .chart {
          margin: 32px;
          div.error-message p {
            margin-left: 50px;
          }
        }
      }
    }
  }
}
