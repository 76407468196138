@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.InvestmentsTable {
  background-color: transparent;
  width: 100%;

  tr th {
    font-size: 17px;
  }

  tr {
    font-size: 15px;
    background-color: rgba(61, 70, 219, .28);
    box-sizing: border-box;
    margin-bottom: 15px !important;
  }

  thead {
    margin: 0;

    tr {
      margin: 0 !important;
      background-color: #0006a2;
    }
  }

  td {
    font-size: 18px;
    font-family: $roboto_light;
  }

  tr th,
  tr td {
    width: 10%;
    padding: 25px 15px 25px 30px;

    &:nth-child(2) {
      width: 15%;
    }
  }

  tr td button {
    background-color: $transparent_state_hover_color;
    border: none;
    cursor: pointer;
    font-size: $xs_base_font_size;
    padding: 15px;
    text-transform: uppercase;
    transition: all 0.4s;
    

    &:hover {
      background-color: $secondary-color;
    }
  }

  .button-details {
    position: relative;
    background-color: transparent;
    width: 100%;

    &::before, &::after  {
      background-color: #3D46DB;
      mix-blend-mode: overlay;
      opacity: 0.37;
      pointer-events: none;
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}
