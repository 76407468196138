@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.BrowseFaq {
  height: 100%;
  width: 100%;

  h2 {
    font-family: $secondary_font;
    font-size: $xl_title_font_size;
  }

  .search {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  .faq-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 70px 0 50px;

    .Button {
      margin: 0 20px 20px 0;

      @include breakpoint(desktop-small) {
        width: 180px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: $transparent_state_hover_color;
      }
    }
  }
}
