@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.InviteEmployeesTable {
  border-collapse: collapse;
  width: 100%;

  tr {
    height: 50px;
    width: 100%;

    th,
    td {
      color: $black;
      font-family: $primary_font;
      padding-left: 30px;
      text-align: left;
    }
  }

  thead {
    tr {
      height: 70px;
      background-color: #0006a2;

      th {
        color: white;
        font-family: $roboto_light;
        font-size: pxToRem($subtitle_size);
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      background-color: rgba(61, 70, 219, 0.28);

      &:nth-child(odd) {
        background-color: rgba(61, 69, 219, 0.089);
      }

      td {
        color: $text_primary_light_color;
        font-family: $primary_font;
        font-size: pxToRem($reading_size);
        height: 100px;

        &:nth-child(6) {
          p {
            color: $yellow;
            border-color: $yellow;

            svg {
              path {
                fill: $yellow;
              }
            }
          }
        }

        &:nth-child(3) {
          span {
            border-radius: 50%;
            display: inline-block;
            height: 12px;
            margin-right: 5px;
            width: 12px;

            &.green {
              background-color: $green_status;
            }

            &.yellow {
              background-color: $yellow_status;
            }

            &.red {
              background-color: $red_status;
            }
          }

          svg {
            height: 12px;
            width: 12px;
          }
        }

        p {
          align-items: center;
          border-bottom: 1px solid $text_primary_light_color;
          box-sizing: border-box;
          color: $text_primary_light_color;
          display: flex;
          font-family: $primary_font;
          font-size: pxToRem($reading_size);
          line-height: pxToRem($reading_size);
          margin: 0;
          width: max-content;

          svg {
            height: 18px;
            margin-bottom: 2px;
            width: 18px;
          }
        }

        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  tr th,
  tr td {
    padding: 25px 15px 25px 30px;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;

      svg {
        height: 20px;
        width: 20px;
      }

      &:hover {
        color: $yellow;
      }
    }
  }
}
