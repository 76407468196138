@import "../../../assets/scss/variables";

.ContributionSummary {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .contribution-description {
    span {
      display: block;
      font-size: $xxl_base_font_size;
      margin-bottom: 30px;
    }

    p {
      color: $text_secondary_color;
      font-size: $xl_base_font_size;
    }
  }

  .contribution-metrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    p {
      font-family: $secondary_font;
      font-size: $xl_base_font_size;
      margin: 30px 0;
      text-decoration: underline;
    }
  }

  .contribution-content-format {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 50%;

    span {
      color: $text_secondary_color;
    }

    img {
      visibility: hidden;
    }

    .active {
      color: $white;
      font-weight: bold;

      img {
        visibility: visible;
      }
    }
  }
}
