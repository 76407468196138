@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.ModalContributions {
  padding: 20px;
  box-sizing: border-box;

  table.investors-table {
    background-color: transparent;
    width: 100%;

    thead {
      margin: 0;

      tr {
        margin: 0 !important;
        background-color: #0006a2;
      }
    }

    tr th,
    tr td {
      padding: 25px 15px 25px 30px;
    }

    tr th {
      font-size: 15px;
      font-family: $roboto_light;
    }

    tr {
      font-size: 13px;
      // background-color: rgba(61, 70, 219, 0.2);
      box-sizing: border-box;
      margin-bottom: 15px !important;
      position: relative;
      color: white;
      background-color: #0b16e05a;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   background-color: #0b16e0;
      //   mix-blend-mode: overlay;
      //   opacity: 0.1;
      //   z-index: -1;
      // }
    }

    tr th,
    tr td {
      width: 10%;
    }

    tr td button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-family: $roboto_light;
      padding: 15px;
      position: relative;
      text-transform: uppercase;
      transition: all 0.4s;

      &::before,
      &::after {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
        pointer-events: none;
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
      }

      &:hover {
        background-color: $secondary-color;
      }
    }
  }

  .contributions-search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px;

    .contributions-count h1 {
      font-size: $xs_title_font_size;
      margin-bottom: 10px;
    }

    .filter-container {
      align-items: center;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      img {
        height: 25px;
        margin: 0 10px;
        width: 25px;
      }
    }
  }
}
