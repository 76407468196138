@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.DeletePopup {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;

  p {
    color: white;
    font-family: Grotesk, sans-serif;
    font-size: pxToRem(35px);
    font-weight: 600;
  }

  .buttons-popup {
    display: flex;
    height: 49px;
    justify-content: center;
    width: 100%;

    .Button {
      background: none;
      border: 2px solid $white;
      color: $white;
      height: 49px;
      margin-right: 71px;
      transition: 0.2s;
      width: 137px;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }
}