@import "../../../../assets/scss/variables";

.MultiSelect {
  .container-select {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      margin: 0 0 10px 0 !important;
      text-align: left !important;
      width: 100% !important;
    }

    p {
      margin: 0;
    }

    .box-select {
      align-items: center;
      border: 2px solid #767e95;
      box-sizing: border-box;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      font-size: 22px;
      gap: 10px;
      min-height: 46px;
      padding: 10px 40px 10px 10px;
      box-sizing: border-box;
      position: relative;
      width: 353px;

      .title-select {
        font-family: $primary_font !important;
        font-size: 18px !important;
        text-align: left !important;
      }

      .arrow-image {
        position: absolute;
        right: 10px;
        width: 20px;
      }

      .button-selected {
        align-items: center;
        background: rgba(22, 72, 159, 0.4);
        display: flex;
        min-height: 25px;
        padding: 5px;

        p {
          color: white;
          font-family: $primary_font !important;
          font-size: 18px !important;
          margin: 0;
          padding: 3px 3px 3px 6px;
        }

        .button {
          align-items: center;
          color: black;
          cursor: pointer;
          display: flex;
          fill: #f8ac00;
          font-size: 12px;
          font-weight: 600;
          height: 100%;
          padding: 0 4px;

          &:hover {
            fill: #de350b;
          }
        }
      }
    }
  }

  .modal-select {
    border-top: unset;
    border: 2px solid #767e95;
    box-sizing: border-box;
    height: 118px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    width: 353px;

    .submit-button {
      align-items: center;
      display: flex;
      min-height: 32px;
      padding: 5px 10px;
      width: 100%;

      p {
        color: white;
        font-family: $primary_font !important;
        font-size: 19px !important;
        margin: 0 10px 0 0;
      }

      &:hover {
        background-color: #15378e;
        cursor: pointer;
      }
    }
  }
}
