@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Table {
  border-collapse: collapse;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;

  thead {
    width: 100%;

    tr {
      box-sizing: border-box;
      display: inline-table;
      margin: 0 0 10px;
      width: calc(100% - 20px);

      th {
        box-sizing: border-box;
        color: white;
        display: table-cell;
        font-family: $roboto_light;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    min-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid rgba(120, 120, 120, 0.3);
      height: 100vh;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 100vh;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white;
    }

    @include breakpoint(desktop-small) {
      &::-webkit-scrollbar {
        width: 10px;
      }
    }

    tr {
      display: table;
    }
  }

  tr {
    height: 55px;
    width: 100%;
  }

  td,
  th {
    box-sizing: border-box;
    padding: 10px;
    text-align: left;
  }

  td {
    p {
      margin: 0;
    }
  }
}
