@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Login {
  height: max-content;
  margin: auto 0;
  height: 100%;

  @include breakpoint(tablet) {
    width: 450px;
  }

  @include breakpoint(desktop-small) {
    width: 500px;
  }

  article {
    margin-bottom: 30px;
    width: 100%;

    & > h2 {
      font-family: $roboto_regular;
      font-size: pxToRem(30px);
      margin: 0 0 10px;
    }

    & > p {
      font-family: $roboto_light;
      font-size: pxToRem(18px);
      margin: 0 0 5px;
    }
  }

  .welcome-text {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 45px 0 0;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    img {
      // padding: 15px;
      width: 110px;
    }

    div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 15px;

      @include breakpoint(tablet) {
        align-items: flex-start;
      }
    }
  }

  form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(desktop-small) {
      height: unset;
      justify-content: space-between;
      width: 100%;
    }

    p {
      &.feedback {
        color: red;
        font-size: 0.8rem;
        margin: 0;
        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;

      .Checkbox {
        color: $text_primary_light_color;
      }

      .forgot-password {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $text_primary_light_color;
        color: $text_primary_light_color;
        cursor: pointer;
        font-size: $sm_base_font_size;
        margin-left: 5px;
        outline: transparent;
        padding: 0;
      }
    }

    .buttons {
      margin: 40px 0;
      height: 100px;

      .microsoft-button {
        width: 100%;
        border: 1px solid white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 33px;
        gap: 20px;
        box-sizing: border-box;

        img {
          margin-right: 10px;
          width: 35px;
        }

        .text-microsoft {
          // border-bottom: 1px solid #767E95;
          font-family: $roboto_light;
          letter-spacing: 1px;
          color: white;
          font-size: 21px;
          font-weight: lighter;
          text-decoration: unset;
        }
      }

      .button-submit {
        position: fixed;
        right: 100px;
        bottom: 0;
        height: 47px;
        min-width: 100px;
        display: flex;
        background-color: rgba(61, 70, 219, 0.3);
        box-sizing: border-box;
        font-family: $roboto_light;
        font-size: 17px;
        border: none;
        gap: 20px;

        .fa6-solid--arrow-right {
          display: inline-block;
          width: 23px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
        }

        @include breakpoint(lg) {
          margin-left: 20px;
          margin-left: unset;
          width: max-content;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      & > .container-buttons {
        display: flex;
        gap: 0 20px;
      }

      .link-style {
        bottom: 20px;
        height: max-content;
        left: 0;
        position: absolute;
        text-transform: lowercase;
        top: unset;
      }
    }
  }
}
