@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.Dashboard {
  box-sizing: border-box;
  // height: calc(100vh);
  overflow-y: scroll;
  padding: 40px 70px 100px;
  width: 100%;
  box-sizing: border-box;

  @include breakpoint(desktop-small) {
    margin: 60px 0 0;
    padding: 0 40px 150px 150px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 100px 150px 150px;
  }


  &::-webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid rgba(120, 120, 120, 0.3);
    height: 100vh;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 100vh;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $white;
  }

  @include breakpoint(desktop-small) {
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
}
