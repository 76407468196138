@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.InformationCheckbox {
  align-items: center;
  background-color: $background_primary_dark_color;
  display: flex;
  height: 50px;
  width: calc(100% - 15px);


  .Checkbox {
    margin: 14px 0 16px 21px;

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      outline: none;
      position: absolute;
      width: 0;

      &:checked {
        ~ .checkmark {
          background-color: $transparent_state_hover_color;

          &:after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      background-color: transparent;
      border: 1px solid $white;
    }
  }

  &:hover {
    input {
      ~ .checkmark {
        background-color: unset;
      }
    }
  }

  p {
    color: $text_primary_light_color;
    font-family: $secondary_font;
    font-size: $base_font_size;
    margin-left: 15px;
  }
}
