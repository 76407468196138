@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.Question {
  align-items: flex-start;
  background-color: $background_primary_dark_color;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px 30px;

  &[open] {
    span {
      transform: rotateX(180deg);
    }
  }

  summary {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: $secondary_font;
    font-size: $xxl_base_font_size;
    height: 48px;
    justify-content: space-between;
    width: 100%;
  }
}
