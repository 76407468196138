@import "../../../assets/scss/variables";

.Accordion {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  
  .title-container {
    background-color: transparent;
    border: 1px solid $text_primary_light_color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    padding: 15px;
    transition: all 0.5s;

    &:hover {
      background-color: $transparent_state_hover_color;
      border: 1px solid $transparent_state_hover_color;
    }

    &:focus {
      background-color: $transparent_state_hover_color;
      border: 1px solid $text_primary_light_color;
      outline: none;
    }
  }

  .content-container {
    padding: 10px 20px;
  
    .title-bloom {
      color: #F3A543;
      margin: 20px 0;
      font-size: 31px !important;
      font-family: $primary_font;
    }
  }
}
