@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.SignUp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(tablet) {
    width: 300px;
  }

  @include breakpoint(tablet-portrait) {
    width: 500px;
  }

  article {
    margin-bottom: 20px;
    width: 100%;

    & > h2 {
      font-family: $secondary_font;
      font-size: $lg_title_font_size;
      margin: 0 0 10px;
    }

    & > p {
      font-size: $lg_base_font_size;
      margin: 0 0 5px;
      max-width: 370px;
    }
  }

  .welcome-text {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 45px 20px 0;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    img {
      padding: 15px;
      width: 135px;
    }

    div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 15px;

      @include breakpoint(tablet) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      &.feedback {
        color: red;
        font-size: 0.8rem;
        margin: 0;
        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      margin: 10px 0 120px;

      .microsoft-button {
        cursor: pointer;
        display: flex;
        margin: 0 0 20px 0;

        img {
          margin-right: 10px;
          width: 21px;
        }

        .text-microsoft {
          border-bottom: 1px solid #767e95;
          font-family: $secondary_font;
        }
      }

      div {
        align-items: center;
        display: flex;

        .GoogleSignIn {
          margin-right: 30px;
        }
      }
    }

    .terms-of-use-button {
      background-color: transparent;
      border: none;
      color: $lightgray;
      cursor: pointer;
      font-family: "Grotesk", sans-serif;
      font-weight: 300;
      margin: 15px 0 0 0;
      outline: none;
      text-align: left;

      span {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .already-registered-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 900;
    margin: 30px 0;
    outline: none;
    text-align: left;
    text-decoration: underline;
  }
}
