@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Contributions {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-bottom: 100px;
  width: 100%;
  padding: 40px 70px 100px;
  box-sizing: border-box;

  @include breakpoint(desktop-small) {
    margin: 60px 0 0;
    padding: 0 91px 150px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 140px 150px;
  }

  .descritption-text {
    // margin: 0 0 40px 40px;
    margin-bottom: 40px;

    h2 {
      font-size: 50px;
      font-family: $roboto_light;
    }

    p {
      font-family: $roboto_light;
      font-size: 18px;
      max-width: 600px;
      word-wrap: break-word;

      span {
        font-weight: bold;
      }
    }
  }

  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // margin: 0 40px;

    .Card {
      height: 100%;

      .title {
        color: white;
        font-family: $roboto_light;
      }

      .bg {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
      }
    }

    .contributions-cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 30px;
      gap: 30px;

      .most-popular-contribution,
      .less-popular-contribution {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-height: 70px;
        padding: 20px 0;
        width: 100%;

        p {
          font-size: $lg_base_font_size;
          text-align: right;

          span.count {
            font-size: $xxl_base_font_size;
            text-align: left;
          }
        }
      }
    }
  }

  .contributions-list {
    margin-top: 30px;
    width: 100%;

    &.functionality {
      margin: 50px 0;
    }

    .container-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;

      button {
        background: rgba(22, 72, 159, 0.4);
        border: 1px solid rgba(22, 72, 159, 0.4);
        color: white;
        cursor: pointer;
      }
    }

    .button-create-investment {
      // background-color: rgba($color: $black, $alpha: 0.5);
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 10px;
      box-sizing: border-box;
      width: 100%;

      &.functionality {
        padding: 0 0 0 10px;
        .Button {
          display: flex;
        }
      }

      .title {
        font-family: $roboto_light;
        font-size: 25px;
      }

      .Button {
        border: unset;
        cursor: pointer;
        display: none;
        font-family: $roboto_regular;
        position: relative;
        background-color: transparent;
    
        &::before, &::after  {
          background-color: #3D46DB;
          mix-blend-mode: overlay;
          opacity: 0.37;
          pointer-events: none;
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }
      }
    }

    table {
      // background-color: rgba($color: $black, $alpha: 0.5);
      background-color: transparent;
      width: 100%;

      tr th {
        font-size: 17px;
      }

      tbody {
        padding: 0;
      }

      tr {
        font-size: 15px;
        background-color: rgba(61, 70, 219, 0.28);
        box-sizing: border-box;
        margin-bottom: 15px !important;
        width: 100%;
      }

      thead {
        margin: 0;

        tr {
          margin: 0 !important;
          background-color: #0006a2;
        }
      }

      tr th {
        font-size: $xs_base_font_size;
      }

      tr th,
      tr td {
        width: 10%;
        padding: 25px 15px 25px 30px;
      }

      tr td button {
        border: none;
        background-color: $transparent_state_hover_color;
        cursor: pointer;
        font-size: $xs_base_font_size;
        padding: 15px;
        text-transform: uppercase;
        transition: all 0.4s;

        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
}
