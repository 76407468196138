@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.InvitesTable {
  display: flex;
  flex-direction: column;
  min-height: 610px;

  .container-input {
    margin: 0 0 30px 0;

    input {
      background-color: #0b14c7;
      border: none;
      box-sizing: border-box;
      color: $white;
      font-size: 22px;
      height: 46px;
      padding: 0 10px;
      width: 353px;

      &::placeholder {
        color: white;
      }
    }
  }

  &.inactive {
    display: none;
  }

  .loading-spinner {
    align-items: center;
    background-color: #0006a2;
    display: flex;
    height: 200px;
    justify-content: center;

    svg {
      height: 150px;
      width: 150px;
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .pagination {
      display: flex;
      justify-content: flex-end;

      .Button {
        margin-left: 15px;
        padding: 5px;
        width: 30px;
        height: 30px;

        &:first-child, &:last-child {
          padding: 5px 15px;
          width: max-content;
        }

        &.active {
          background-color: $primary-color-hovered;
          border-color: $primary-color-hovered;
        }
      }

      span {
        align-items: flex-end;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-left: 15px;
        width: 30px;
      }
    }
  }
}
