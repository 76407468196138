@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.PopUpCheck {
  align-items: center;
  background-color: #000000b8;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &.active {
    display: flex;
  }

  .pop-up {
    background-color: #00057f;
    box-shadow: 0 3px 20px #00057f;
    display: flex;
    height: 250px;
    justify-content: center;
    padding: 20px;
    width: 250px;

    @include breakpoint(desktop-small) {
      align-items: center;
      flex-direction: column;
      height: 184px;
      justify-content: space-evenly;
      min-width: 409px;
    }
  }

  .check {
    align-items: center;
    border-radius: 50%;
    border: 2px solid #00ff6c;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    padding: 5px;
    width: 60px;

    .game-icons--check-mark {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2300FF6C' d='M17.47 250.9C88.82 328.1 158 397.6 224.5 485.5c72.3-143.8 146.3-288.1 268.4-444.37L460 26.06C356.9 135.4 276.8 238.9 207.2 361.9c-48.4-43.6-126.62-105.3-174.38-137z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: inline-block;
      height: 38px;
      width: 36px;
    }
  }

  .container-pop-up {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: max-content;
    justify-content: center;

    p {
      font-family: $roboto_light;
      line-height: 30px;
      text-align: center;
    }

    h2 {
      align-items: center;
      display: flex;
      font-size: $lg_base_font_size;
      margin-top: 0;
      text-align: center;

      @include breakpoint(lg) {
        max-width: 600px;
        text-align: center;
      }
    }

    .Button {
      width: 150px;
    }

    div {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      width: 250px;
    }
  }
}
