@import "../../../assets/scss/variables";

.ContributionsActiveExecutions {
  height: 100%;

  .contributions-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;

    .active-executions-count {
      p {
        font-size: $base_font_size;
        margin-top: 10px;
      }

      :first-child {
        font-size: $xs_title_font_size;
      }
    }
  }

  .investors-list {
    margin-left: -50px;
    margin-top: 80px;

    table {
      background-color: rgba($color: $black, $alpha: 0.5);
      width: 100%;

      tr th {
        font-size: $xs_base_font_size;
      }

      tr th,
      tr td {
        width: 10%;
      }

      tr td button {
        border: none;
        background-color: $transparent_state_hover_color;
        cursor: pointer;
        font-size: $xs_base_font_size;
        padding: 15px;
        text-transform: uppercase;
        transition: all 0.4s;

        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
}
