@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Support {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @include breakpoint(lg) {
    height: calc(100vh - 60px);
    overflow-y: scroll;
    padding: 40px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid rgba(120, 120, 120, 0.3);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $white;
  }
}
