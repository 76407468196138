@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.Access {
  box-sizing: border-box;
  min-height: calc(100vh - 70px);
  // padding: 50px 63px;

  padding: 40px 70px 100px;
  box-sizing: border-box;

  @include breakpoint(desktop-small) {
    margin: 60px 0 0;
    padding: 0 91px 150px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 140px 150px;
  }
}
