@import "../../../../assets/scss/variables";

.Checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: $sm_base_font_size;
  padding-left: 30px;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    width: 0;

    &:checked {
      ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    background-color: transparent;
    border: 2px solid $border_color;
    height: 15px;
    left: 0;
    margin-right: 5px;
    position: absolute;
    width: 15px;

    &:after {
      border: solid $text_primary_light_color;
      border-width: 0 2px 2px 0;
      content: "";
      display: none;
      height: 10px;
      left: 4px;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      width: 6px;
    }
  }
}
