@import "../../../assets/scss/variables";

.ContributionDetails {
  background: url("../../../assets/images/background.png") 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 50%;
  margin: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 20;

  .header {
    display: flex;
    height: 45%;
    justify-content: space-between;
    position: fixed;
    width: 100%;

    > button {
      background: none;
      border: none;
      color: $yellow;
      cursor: pointer;
      font-family: $primary_font;
      font-size: $sm_base_font_size;
      height: 35px;
      margin: 30px 50px 0 0;
      text-transform: uppercase;
    }

    .contribution-container {
      margin: 50px 0 0 50px;

      p {
        color: $text_secondary_color;
        font-size: $lg_base_font_size;
      }

      .contribution-info {
        align-items: center;
        display: flex;
        margin-top: 20px;

        p {
          color: $text_primary_light_color;
          font-family: $secondary_font;
          font-size: $sm_title_font_size;

          &:nth-child(2),
          &:nth-child(3) {
            color: $text_secondary_color;
            font-family: $primary-font;
            font-size: $xxl_base_font_size;
            margin: 15px 0 0 20px;
          }

          .TopicSymbol {
            margin-left: 20px;
          }
        }
      }
    }

    .tabs {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      width: 600px;
    }
  }

  section {
    background: $background_primary_dark_color;
    height: 100%;
    margin-top: 280px;
    overflow-y: scroll;
    padding: 50px;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid rgba(120, 120, 120, 0.3);
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $transparent_state_hover_color;
    }
  }
}
