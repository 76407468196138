@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.InviteModalCollaborators {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  .invite-modal {
    background-image: url("../../../assets/images/bg-mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    box-sizing: border-box;
    color: white;
    height: 100%;
    overflow-y: scroll;
    padding: 24px 41px 100px 41px;
    position: relative;
    width: 100%;

    .close-button {
      align-items: center;
      background: none;
      border: none;
      color: #ffbd0a;
      cursor: pointer;
      display: flex;
      font-family: $roboto_light;
      font-size: pxToRem(17px);
      gap: 10px;
      position: absolute;
      right: 41px;
      top: 46px;

      @include breakpoint(desktop-medium) {
        right: 100px;
      }

      .material-symbols--close {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffbd0a' d='M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z'/%3E%3C/svg%3E");
      }
    }

    .invite-modal-header {
      margin: 55px 0 24px 0;

      @include breakpoint(desktop-large) {
        margin: 90px 100px 24px 100px;
      }

      h2 {
        align-items: center;
        display: flex;
        font-family: $roboto_light;
        font-size: pxToRem(32px);
        font-weight: 300;
        margin: 0;

        .solar--alt-arrow-right-line-duotone {
          display: inline-block;
          width: 40px;
          height: 40px;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m9 5l6 7l-6 7'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }
      }

      p {
        color: white;
        font-family: $roboto_light;
        font-size: pxToRem(18px);
        margin-top: 22px;
      }
    }

    .container-box-invite {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: 90px 0 24px 0;
      width: 100%;

      @include breakpoint(desktop-large) {
        padding: 90px 100px 24px 100px;
      }

      form {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 30px;
      }

      .invite-section {
        display: flex;
        flex-direction: column;
        padding: 58px 0;
        width: 50%;

        &.expanded {
          width: 100%;
        }

        h3 {
          font-family: $roboto_light;
          font-size: pxToRem(26px);
          margin: 0 0 30px 0;

          &:nth-child(3) {
            margin-top: 50px;
          }
        }

        input {
          background-color: transparent;
          border: 1px solid #7376b7;
          box-sizing: border-box;
          font-family: $roboto_light;
          font-size: 15px;
          max-width: 381px;
          padding: 15px;

          &::placeholder {
            color: #cccfff99;
          }
        }

        .custom-select-container {
          position: relative;
          width: 100%;

          .select-wrapper {
            max-width: 381px;
            position: relative;
            width: 100%;
          }

          &.next-button {
            display: flex;
            flex-direction: row !important;
            align-items: center;

            .add-button {
              background-color: #00059e;
              border: none;
              border-radius: 50%;
              margin-left: 20px;
              color: #ffffff;
              width: 40px;
              height: 40px;
              font-size: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0022b6;
              }
            }
          }

          .custom-select {
            appearance: none;
            background-color: transparent;
            border: 1px solid #7376b7;
            color: #fff;
            cursor: pointer;
            font-family: $roboto_light;
            font-size: 14px;
            font-size: 15px;
            outline: none;
            padding: 15px 40px 15px 16px;
            width: 100%;

            &:hover {
              border-color: #7376b7;
            }

            option {
              background: #080c48;
              color: #fff;
            }
          }

          .custom-icon {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
            pointer-events: none;
            position: absolute;
            right: 16px;
            top: 5px;

            .weui--arrow-outlined {
              display: inline-block;
              width: 20px;
              height: 40px;
              transform: rotate(90deg);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 24'%3E%3Cdefs%3E%3Cpath id='weuiArrowOutlined0' fill='%237376B7' d='m7.588 12.43l-1.061 1.06L.748 7.713a.996.996 0 0 1 0-1.413L6.527.52l1.06 1.06l-5.424 5.425z'/%3E%3C/defs%3E%3Cuse fill-rule='evenodd' href='%23weuiArrowOutlined0' transform='rotate(-180 5.02 9.505)'/%3E%3C/svg%3E");
            }
          }
        }

        .inputs-box {
          display: flex;
          flex-direction: column;
          gap: 32px;

          div {
            display: flex;
            flex-direction: column;

            .error {
              color: red;
              font-family: $roboto_regular;
              font-size: pxToRem(13px);
              letter-spacing: 1px;
              margin: 5px 0 0 0;
            }
          }
        }
      }

      .role-section {
        padding: 58px 40px 97px;
        position: relative;
        width: 80%;
        z-index: 1;

        .custom-select-container {
          position: relative;
          width: 100%;

          .select-wrapper {
            max-width: 381px;
            position: relative;
            width: 100%;
          }

          &.next-button {
            display: flex;
            flex-direction: row !important;
            align-items: center;

            .add-button {
              background-color: #00059e;
              border: none;
              border-radius: 50%;
              margin-left: 20px;
              color: #ffffff;
              width: 40px;
              height: 40px;
              font-size: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0022b6;
              }
            }
          }

          .custom-select {
            appearance: none;
            background-color: transparent;
            border: 1px solid #7376b7;
            color: #fff;
            cursor: pointer;
            font-family: $roboto_light;
            font-size: 14px;
            font-size: 15px;
            outline: none;
            padding: 15px 40px 15px 16px;
            width: 100%;

            &:hover {
              border-color: #7376b7;
            }

            option {
              background: #080c48;
              color: #fff;
            }
          }

          .custom-icon {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
            pointer-events: none;
            position: absolute;
            right: 16px;
            top: 5px;

            .weui--arrow-outlined {
              display: inline-block;
              width: 20px;
              height: 40px;
              transform: rotate(90deg);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 24'%3E%3Cdefs%3E%3Cpath id='weuiArrowOutlined0' fill='%237376B7' d='m7.588 12.43l-1.061 1.06L.748 7.713a.996.996 0 0 1 0-1.413L6.527.52l1.06 1.06l-5.424 5.425z'/%3E%3C/defs%3E%3Cuse fill-rule='evenodd' href='%23weuiArrowOutlined0' transform='rotate(-180 5.02 9.505)'/%3E%3C/svg%3E");
            }
          }
        }

        .inputs-box {
          display: flex;
          flex-direction: column;
          gap: 32px;

          div {
            display: flex;
            flex-direction: column;

            .error {
              color: red;
              font-family: $roboto_regular;
              font-size: pxToRem(13px);
              letter-spacing: 1px;
              margin: 5px 0 0 0;
            }
          }

          input {
            background-color: transparent;
            border: 1px solid #7376b7;
            box-sizing: border-box;
            font-family: $roboto_light;
            font-size: 15px;
            max-width: 381px;
            padding: 15px;

            &::placeholder {
              color: #cccfff99;
            }
          }
        }

        &::before {
          background: black 0% 0% no-repeat padding-box;
          content: "";
          height: 100%;
          left: 0;
          mix-blend-mode: overlay;
          opacity: 0.28;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }

        .submit-button {
          align-items: center;
          background: #00059e;
          border-radius: 0;
          bottom: 0;
          display: flex;
          font-family: $roboto_light;
          font-size: pxToRem(17px);
          gap: 25px;
          height: 86px;
          justify-content: center;
          position: absolute;
          right: 0;
          width: 266px;

          .fa6-solid--arrow-right {
            display: inline-block;
            width: 35px;
            height: 25px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
          }

          &:hover {
            opacity: 0.8;
          }
        }

        h3 {
          font-family: $roboto_light;
          font-size: pxToRem(24px);
          margin: 0 0 50px 0;
        }

        .download-csv {
          color: rgba(255, 255, 255, 0.377);
          font-family: $roboto_light;
          font-size: pxToRem(17px);

          span {
            cursor: pointer;
            border-bottom: 1px solid white;
            color: white;
            font-size: pxToRem(17px);
            font-weight: 500;
            color: white;
          }
        }

        .DragAndDropUpload {
          align-items: center;
          svg {
            width: 80px;
            height: 90px;
            path {
              fill: #cccfff;
            }
          }

          .describe-drag {
            color: rgba(255, 255, 255, 0.46);
            font-family: $roboto_light;
            font-size: pxToRem(20px);
            margin-right: 5px;
          }
        }

        .info-describe {
          color: rgba(255, 255, 255, 0.46);
          font-family: $roboto_light;
          font-size: pxToRem(20px);
          margin: 0;
        }
      }
    }

    .submit-button {
      background: #0056d2;
      border-radius: 4px;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 14px;
      padding: 10px 16px;
      width: 100%;

      &:hover {
        background: #0041a7;
      }
    }
  }
}
