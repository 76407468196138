@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.PopUp {
  .container-pop-up {
    display: flex;

    .popup-image-success {
      background: url("../../../assets/images/popup-mail.png") no-repeat center center;
      height: 463px;
      left: -120px;
      position: relative;
      top: -73px;
      width: 515px;
    }

    .popup-success {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: $white;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(35px);
        font-weight: 600;
        margin: 0;
      }
    }
  }
}