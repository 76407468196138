@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.AccessHeader {
  .container-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 100px;
    .info-text {
      h1 {
        font-family: $roboto_light;
        font-size: pxToRem(32px);
        margin: 0;
      }

      p {
        font-family: $roboto_light;
        font-size: pxToRem(18px);
        margin: 22px 0 0 0;
      }
    }

    .container-buttons {
      display: flex;
      gap: 20px;

      button {
        align-items: center;
        background: #00059e;
        border-radius: 0;
        border: unset;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        font-family: $roboto_light;
        font-size: pxToRem(15px);
        gap: 10px;
        height: 86px;
        justify-content: space-between;
        padding: 0 15px;
        text-wrap: nowrap;
        width: 260px;

        @include breakpoint(desktop-large) {
          font-size: pxToRem(17px);
          gap: 25px;
          width: 350px;
        }

        .fa6-solid--arrow-right {
          display: inline-block;
          width: 30px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");

          @include breakpoint(desktop-large) {
            height: 25px;
            width: 35px;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .navigation {
    margin-bottom: 50px;

    button {
      background-color: transparent;
      border: none;
      box-sizing: border-box;
      color: $text_primary_light_color;
      cursor: pointer;
      font-family: $roboto_regular;
      font-size: pxToRem(27px);
      margin-right: 30px;
      outline: transparent;
      padding: 10px 0;
      width: max-content;

      &:nth-child(1) {
        border-right: none;
      }

      &.active {
        border-bottom: 3px solid #ffc400;
        color: $white;
      }

      .tooltip {
        display: none;
      }

      &.disabled {
        cursor: not-allowed;

        .tooltip {
          display: inline-block;
          position: relative;

          .tooltiptext {
            background-color: $transparent_state_hover_color;
            border-radius: 6px;
            color: $white;
            font-family: $secondary_font;
            padding: 15px 0;
            position: absolute;
            text-align: center;
            visibility: hidden;
            width: 300px;
            z-index: 1;
          }
        }
      }

      &:hover.disabled {
        .tooltip .tooltiptext {
          visibility: visible;
        }
      }
    }
  }

  // .actions {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-bottom: 50px;

  //   .invite-buttons {
  //     align-items: flex-end;
  //     display: flex;
  //     width: max-content;

  //     .Button {
  //       margin-right: 30px;
  //       position: relative;
  //       background-color: transparent;
  //       font-family: $roboto_light;
  //       font-weight: 600;
  //       border: unset;

  //       &::before,
  //       &::after {
  //         background-color: #3d46db;
  //         mix-blend-mode: overlay;
  //         opacity: 0.37;
  //         pointer-events: none;
  //         content: "";
  //         position: absolute;
  //         bottom: 0;
  //         top: 0;
  //         right: 0;
  //         left: 0;
  //       }
  //     }
  //   }

  //   .remove-by-slot {
  //     background-color: transparent;
  //     border: none;
  //     border-bottom: 2px solid $text_primary_light_color;
  //     color: $text_primary_light_color;
  //     cursor: pointer;
  //     display: flex;
  //     font-family: $primary_font;
  //     font-size: pxToRem($subtitle_size);
  //     font-weight: 500;
  //     height: 35px;
  //     outline: none;
  //     padding: 5px;

  //     svg {
  //       height: 25px;
  //       margin-left: 5px;
  //     }
  //   }
  // }
}
