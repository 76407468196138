@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.Logo {
  display: flex;

  svg {
    height: 60px;
    width: 72px;
  }
}
