@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.Grade {
  align-items: baseline;
  color: white;
  display: flex;
  font-family: $roboto_regular;
  font-size: $lg_base_font_size;
  text-align: left;

  svg {
    -webkit-transform: rotate(-45deg);
    height: 15px;
    margin-left: 5px;
    transform: rotate(-45deg);
    width: 15px;
  }

  .stagnant-symbol {
    background-color: $text_primary_light_color;
    display: inline-block;
    height: 3px;
    margin: auto 5px;
    width: 10px;
  }

  .maxGrade {
    font-size: $lg_base_font_size;
    margin-left: 5px;
  }
}
