@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.Button {
  align-items: center;
  background-blend-mode: overlay;
  background-color: $background_primary_dark_color;
  border: 1px solid $border_color;
  box-shadow: $box_shadow;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $secondary_font;
  font-size: $sm_base_font_size;
  font-weight: 400;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 34px;
  text-transform: uppercase;
  width: max-content;

  p {
    color: $white;
    display: none;
    font-weight: normal;
    position: absolute;
    top: 100%;
  }

  &:hover, &:focus {
    background-color: $transparent_state_hover_color;

    p {
      display: block;
    }
  }

  &.top {
    border-right: none;
    border-top: none;
    position: absolute;
    right: -20px;
    top: -20px;
  }

  &.bottom {
    bottom: -20px;
    border-bottom: none;
    border-right: none;
    border-top: 1px solid $border_color;
    position: absolute;
    right: -20px;
  }

  &.cursor-not-allowed {
    cursor: not-allowed;
  }

  &.link-style {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $text_primary_light_color;
    display: inline-block;
    font-family: $secondary_font;
    font-size: $lg_base_font_size;
    margin: 0 10px;
    padding: unset;
    position: unset;
    text-transform: capitalize;

    &:hover {
      background-color: unset;
    }
  }

  &.secondary-color {
    border-color: $primary-color;
    color: $primary-color;
    font-family: $primary_font;
    font-size: $xs_base_font_size;
    font-weight: 500;
    padding: 5px 0;
    text-transform: unset;
  }
}

