@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.CreateInvestment {
  align-items: flex-start;
  background: #0000003b 0 0 no-repeat padding-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 5%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;

  &.active {
    display: flex;
  }

  .container-create {
    // background: transparent;
    box-sizing: border-box;
    max-width: 1093px;
    min-height: 82%;
    padding-left: 72px;
    position: relative;
    width: 100%;
    z-index: 2;

    .bg {
      background-color: #0006a2;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    .close-modal {
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      span {
        color: $yellow;
        cursor: pointer;
        font-family: $primary_font;
        font-size: $sm_base_font_size;
        height: 35px;
        margin: 0;
        text-transform: uppercase;
      }
    }

    .title-create {
      color: $white;
      font-family: $secondary_font;
      font-size: 34px;
    }

    .form-create {
      display: flex;
      flex-direction: column;
      gap: 16px 0;
      margin-top: 40px;
      padding-right: 30px;

      .Button {
        background-color: transparent;
        border: unset;
        cursor: pointer;
        font-family: $primary_font;
        position: relative;

        &::before,
        &::after {
          background-color: #3d46db;
          mix-blend-mode: overlay;
          opacity: 0.37;
          pointer-events: none;
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }

        &:hover {
          filter: brightness(0.8);
        }
      }

      .container-button {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-left: 30px;
        width: 100%;

        .message-error {
          color: red;
          display: none;
          margin-right: 30px;

          &.active {
            display: flex;
          }
        }
      }

      .box-input {
        align-items: center;
        display: flex;

        &.description {
          align-items: flex-start;

          textarea {
            background-color: rgba(255, 255, 255, 0.858);
            color: $black;
            border: none;
            box-sizing: border-box;
            padding: 0 10px;
            resize: none;
          }
        }

        p {
          font-family: $secondary_font;
          font-size: 22px;

          &.generate-code {
            text-align: center;
            width: 310px;
          }
        }

        label {
          font-family: $primary_font;
          font-size: 22px;
          margin-right: 34px;
          text-align: right;
          width: 170px;
        }

        input {
          background-color: rgba(255, 255, 255, 0.858);
          border: none;
          box-sizing: border-box;
          color: $black;
          font-size: 22px;
          height: 46px;
          padding: 0 10px;
          width: 353px;
        }

        &.select {
          min-height: 458px;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px 0;

          label {
            text-align: left;
          }

          .multi-select {
            display: flex;
            align-items: flex-start;
            justify-content: space-between !important;
            width: 100%;

            .MultiSelect {
              .container-select {
                .box-select {
                  width: 440px;
                }
              }

              .modal-select {
                width: 440px;
              }
            }
          }
        }

        .Select {
          div {
            align-items: center;
            flex-direction: row;

            select {
              height: 46px;
              min-width: 272px !important;
            }
          }
        }
      }

      .box-checks {
        display: flex;
        margin-top: 20px;

        .title-checks {
          font-family: $primary_font;
          font-size: 22px;
          margin-right: 34px;
          text-align: right;
          width: 170px;
        }

        .container-checks {
          display: flex;
          gap: 0 20px;

          label {
            font-family: $secondary_font;
            font-size: 22px;
          }

          input {
            transform: scale(1.5);
            width: 40px;
          }
        }
      }
    }
  }
}
