@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Notification {
  animation: slideIn 0.5s ease-in-out forwards;
  background: rgba(22, 72, 159, 0.4);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: $primary-font;
  font-size: 20px;
  min-width: max-content;
  opacity: 0;
  padding: 16px;
  position: fixed;
  right: -100px;
  top: 10%;
  transform: translateX(0%);
  z-index: 60;

  .progress-bar {
    background-color: #fff;
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(0%) translateY(0%);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
}
