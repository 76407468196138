@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.NewPassword {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(md) {
    width: 300px;
  }

  @include breakpoint(lg) {
    width: 800px;
  }

  h2 {
    font-family: $secondary_font;
    font-size: $lg_title_font_size;
    margin: 0 0 30px;
  }

  form {
    margin-bottom: 80px;
    width: 500px;
  }

  .registered {
    text-transform: unset;
  }
}
