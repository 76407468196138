@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.Tag {
  background-color: $background_primary_dark_color;
  background-image: url("../../../assets/images/check.svg");
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 23px;
  border-radius: 50px;
  color: $white;
  cursor: pointer;
  font-family: Grotesk, sans-serif;
  font-size: pxToRem($reading_size);
  font-weight: 400;
  padding: 10px 15px 10px 40px;
  width: max-content;

  &:hover {
    background-image: url("../../../assets/images/close.svg");
  }
}
