@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ManagersTable {
  background-color: transparent;
  border-collapse: collapse;
  width: 100%;

  tr {
    height: 50px;
    width: 100%;

    th,
    td {
      color: $black;
      font-family: $roboto_light;
      padding-left: 30px;
      text-align: left;
      width: 20%;

      &:nth-child(5) {
        padding-left: 0;
        width: 1%;
      }

      &:last-child {
        padding-right: 15px;
        width: 1%;
      }
    }
  }

  tr th,
  tr td {
    padding: 25px 15px 25px 30px;
  }

  thead {
    tr {
      background-color: #0006a2;
      height: 62px;

      th {
        color: white;
        font-family: $roboto_light;
        font-size: pxToRem(15px);
      }
    }
  }

  tbody {
    position: relative;
    z-index: 1;

    &::before {
      background: black 0% 0% no-repeat padding-box;
      content: "";
      height: 100%;
      left: 0;
      mix-blend-mode: overlay;
      opacity: 0.28;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    tr {
      background-color: transparent;

      td {
        color: white;
        font-family: $roboto_regular;
        font-size: pxToRem(15px);
        height: 62px;

        .status {
          align-items: center;
          display: flex;
          font-size: pxToRem(15px);
          gap: 8px;

          .dot {
            animation: pulse 1.5s infinite;
            background-color: #00ff6c;
            border-radius: 50%;
            box-shadow: 0 0 5px 2px #00ff6c;
            height: 10px;
            width: 10px;
          }

          @keyframes pulse {
            0% {
              box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.6);
            }
            50% {
              box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.4);
            }
            100% {
              box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.6);
            }
          }
        }

        &:nth-child(2) {
          span {
            border-radius: 50%;
            display: inline-block;
            height: 12px;
            margin-right: 5px;
            width: 12px;

            &.green {
              background-color: $green_status;
            }

            &.yellow {
              background-color: $yellow_status;
            }

            &.red {
              background-color: $red_status;
            }
          }
        }

        &.edit {
          p {
            align-items: center;
            color: #ffbd0a;
            cursor: pointer;
            display: flex;
            font-family: $roboto_light;
            font-size: pxToRem(15px);
            gap: 5px;
            width: max-content;
          }

          svg {
            height: 20px;
            margin-bottom: 2px;
            width: 20px;

            path {
              fill: #ffbd0a;
            }
          }
        }

        &.trash {
          svg {
            height: 20px;
            margin-top: 5px;
            width: 20px;

            path {
              fill: #ffbd0a;
            }
          }
        }

        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
