@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.ForgotPassword {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(md) {
    width: 300px;
  }

  @include breakpoint(lg) {
    width: 500px;
  }

  .BackButton {
    margin-bottom: 50px;
  }

  .registered {
    margin: 80px 0 10px;
    text-transform: unset;
  }
}
