@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.InvestorsInInvestment {
  .header-investor {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 170px;
    justify-content: center;
    padding: 0 50px;
    width: 100%;

    p {
      margin: 0;
      font-family: $primary_font;
      font-size: 18px;

      &:nth-child(1) {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }
  }

  .investors-list {
    background-color: rgba($color: $black, $alpha: 0.5);
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;

    table.investors-table {
      background-color: rgba($color: $black, $alpha: 0.2);
      height: 100%;
      width: 100%;

      tr th {
        font-size: $xs_base_font_size;
      }

      tr th,
      tr td {
        padding: 30px;
        width: 10%;
      }

      tbody {
        min-height: 430px;
        max-height: 800px
      }

      tr td button {
        background-color: $transparent_state_hover_color;
        border: none;
        cursor: pointer;
        font-size: $xs_base_font_size;
        padding: 15px;
        text-transform: uppercase;
        transition: all 0.4s;

        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
}
