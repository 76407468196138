@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.InviteEmployee {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 20px 29px 20px 27px;
  width: 30%;

  h2 {
    color: $text_primary_light_color;
    font-family: $secondary_font;
    font-size: $xxl_base_font_size;
    font-weight: 600;
  }

  .container-new-people {
    display: flex;
    flex-direction: column;
    height: 370px;
    justify-content: center;

    .input-new-people {
      align-items: center;
      border-bottom: 1px solid #BDBDBD;
      display: flex;
      position: relative;

      span {
        color: #BDBDBD;
        font-size: $lg_base_font_size;
      }

      .Input {
        width: 100%;

        input {
          border: none;
          color: $text_primary_light_color;
          font-size: $lg_base_font_size;
          padding-left: 10px;
          width: 100%;

          &:focus {
            outline-style: none;
          }
        }
      }

      .container-email {
        box-shadow: 0 3px 6px #00000029;
        display: flex;
        flex-direction: column;
        margin: 0 144px 0 47px;
        padding-top: 11px;
        position: absolute;
        top: 43px;
        width: 74%;
      }
    }
  }

  .tags-container {
    margin-bottom: 15px;
  }
}
