@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.TopicSymbol {
  border: 2px solid $text_primary_light_color;
  display: inline-block;
  font-size: pxToRem($reading_size);
  font-weight: bold;
  padding: 5px;
  text-align: center;
  width: 75px;
}
