@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.CheckboxesContainer {
  height: calc(100vh - 300px);
  width: 60%;

  h2 {
    color: $white;
    font-family: $primary_font;
    font-size: $lg_base_font_size;
  }

  .Checkbox {
    align-items: center;
    background-color: $background_primary_dark_color;
    color: $white;
    display: flex;
    font-family: $secondary_font;
    font-size: $base_font_size;
    height: 50px;
    padding: 0 0 0 50px;

    &.permission-checkbox.disabled {
      color: $placeholder_color;
    }

    .checkmark {
      background-color: transparent;
      left: 10px;

      &:after {
        border-color: $white;
      }
    }


    &:hover {
      input {
        ~ .checkmark {
          background-color: $transparent_state_hover_color;
        }
      }
    }
  }

  .checkboxes {
    height: 83%;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid rgba(120, 120, 120, 0.3);
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white;
    }
  }

  .create-unit {
    margin-top: -50px;
    max-width: 350px;
  }
}
