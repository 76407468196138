@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.InvestmentClassification {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0;

  .classification-description {
    margin-bottom: 30px;
    padding: 0 30px;
    width: 663px;

    p {
      font-family: $primary_font;
      font-size: 20px;
    }
  }

  details.Accordion {
    width: 737px;

    .taxonomy {
      align-items: center;
      display: flex;

      span.taxonomy-grade {
        font-size: 26px;
        font-weight: bold;
      }

      span.taxonomy-action {
        font-family: $primary_font;
        font-size: 26px;
        font-weight: 400;
        margin-left: 15px;
        text-transform: capitalize;
      }
    }

    .bloom {
      display: flex;

      span {
        font-family: $primary_font, sans-serif;
        font-size: 24px;
        margin: 0;
      }
    }
  }
}
