@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.CreateNotifyTask {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 5%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  &.active {
    display: flex;
  }

  .container-create {
    align-items: center;
    background: #172f6c;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 271px;
    justify-content: center;
    position: relative;
    width: 524px;

    .container-title {
      p {
        font-family: $secondary_font;
        font-size: 27px;
        margin: 0 0 10px 0;
        text-align: center;
      }
    }

    .container-buttons {
      display: flex;
      gap: 0 27px;
      margin-top: 25px;

      .Button {
        background: rgba(22, 72, 159, 0.4);
        border: unset;
        cursor: pointer;
        font-family: $primary_font;
        height: 72px;
        width: 148px;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
}
