@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.EmployeeInformation {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: flex-start;
  padding: 0 39px 0 27px;
  width: 68%;

  h1 {
    color: $text_primary_light_color;
    font-family: $primary_font;
    font-size: $xs_title_font_size;
    margin: 15px 0 30px;
  }

  .checkbox-access {
    display: flex;
    justify-content: space-between;
  }

  .Button {
    align-self: flex-end;
    margin: 0;
    width: max-content;
  }
}
