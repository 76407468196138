@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.SelectCompany {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 50px 0;
  width: 100%;

  .Logo {
    left: 50px;
    position: absolute;
    top: 0;

    svg {
      height: 150px;
      width: 100px;
    }
  }

  .container-selectcompany {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 900px;

    .title-selectcompany {
      width: 900px;

      h1 {
        font-family: $roboto_light;
        font-size: 40px;
        text-align: center;
      }

      h2 {
        font-family: $roboto_light;
        font-size: 29px;
        font-weight: 500;
        margin: 90px 0 40px 0;
        text-align: center;
        width: 100%;
      }
    }

    .cards-selectcompany {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 45px;
      margin-top: 45px;
      width: 900px;

      .add-selectcompany {
        align-items: flex-end;
        background-color: rgba(61, 70, 219, 0.4);
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 253px;
        justify-content: center;
        padding-bottom: 70px;
        width: 253px;

        &:hover {
          border: 1px solid rgba(61, 70, 219, 0.4);
          transition: box-shadow 300ms;
        }

        .textadd-selectcompany {
          p {
            color: white;
            font-family: $roboto_light;
            margin-top: 30px;
          }

          .span-selectorcompany {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 20px;
            justify-content: space-between;
            position: relative;

            span {
              background-color: #b6b6b6;
              border-radius: 50px;
              height: 2px;
              width: 53px;
              z-index: 2;

              &:nth-child(1) {
                margin-bottom: -5px;
                transform: rotate(90deg);
              }

              &:nth-child(2) {
                margin-bottom: 17px;
                transform: rotate(-179deg);
              }
            }
          }
        }
      }
    }
  }
}
