@import "../../../assets/scss/variables";

.BackButton {
  align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  cursor: pointer;
  display: flex;
  font-family: $secondary_font;
  height: max-content;
  justify-content: center;
  outline: none;
  padding: 1px;
  text-transform: uppercase;

  &:focus {
    border-bottom-color: $border_color;
  }

  svg {
    height: 20px;
    margin-right: 5px;
  }

  &.inverted {
    color: $black;

    svg {
      filter: invert(1);
    }
  }
}
