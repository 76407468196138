@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.CommunityRegisterFinished {
  margin: auto 0;
  width: 100%;

  .check {
    align-items: center;
    border-radius: 50%;
    border: 2px solid #00FF6C;
    display: flex;
    flex-direction: column;
    height: 55px;
    justify-content: center;
    padding: 5px;
    width: 55px;
    margin-bottom: 40px;

    .material-symbols-light--check {
      display: inline-block;
      width: 55px;
      height: 55px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2300FF6C' d='m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.714z'/%3E%3C/svg%3E");
    }
  }

  h2 {
    font-family: $roboto_light;
    font-size: $lg_title_font_size;
  }

  p {
    font-family: $roboto_light;
    font-size: 20px;
    margin-bottom: 70px;
  }

  .Button {
    background-color: #0005B2;
    border: unset;
    font-family: $roboto_light;
    font-size: 20px;

    &:hover {
      filter: brightness(0.9);
    }

    .ph--arrow-right-bold {
      display: inline-block;
      margin-left: 30px;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='m224.49 136.49l-72 72a12 12 0 0 1-17-17L187 140H40a12 12 0 0 1 0-24h147l-51.49-51.52a12 12 0 0 1 17-17l72 72a12 12 0 0 1-.02 17.01'/%3E%3C/svg%3E");
    }
  }
}
