@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.AddInvestors{
  width: 100%;

  h2 {
    font-family: $roboto_light;
    margin: 50px 0;
  }

  h2 {
    font-size: 38px;
    margin-bottom: 15px;
  }

  p {
    font-family: $roboto_light;
    &:nth-child(3) {
      margin-bottom: 50px;
    }

    &:nth-child(4) {
      margin-bottom: 10px;
    }

    &.error-message {
      color: $red_status;
    }

    &.email-warning {
      color: rgba(255, 255, 255, 0.50);
      margin-top: 10px;
    }

    &.upload-button {
      color: rgba(255, 255, 255, 0.50);

      .UploadButton {
        opacity: 100% !important;
        width: 160px;

        &::before {
          font-family: $roboto_light;
          color: white;
        }
      }
    }
  }

  span {
    cursor: pointer;
    display: block;
    margin: 0 0 50px 0;
    text-decoration: underline;
  }

  label {
    color: $text_secondary_color;
    font-family: $secondary_font;
    font-size: $lg_base_font_size;
  }

  .top-info, .top-info div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    img {
      border-radius: 50%;
      height: 50px;
      margin-right: 20px;
      width: 50px;
    }
  }

  .DragAndDropUpload {
    border: 1px dashed rgba(255, 255, 255, 0.401);
    
    svg {
      height: 83px;
      width: 79px;

      path {
        fill: #ffffff;
        opacity: 50%;
      }
    }
  }

  .Button {
    &:nth-child(2) {
      margin-right: 30px;
    }
  }

  .Button {
    align-items: center;
    background-color: #0005B2;
    border: unset;
    display: flex;
    font-family: $roboto_light;
    font-size: 20px;

    &:hover {
      filter: brightness(0.9);
    }

    .ph--arrow-right-bold {
      display: inline-block;
      margin-left: 30px;
      width: 25px;
      margin: 0 0 0 30px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='m224.49 136.49l-72 72a12 12 0 0 1-17-17L187 140H40a12 12 0 0 1 0-24h147l-51.49-51.52a12 12 0 0 1 17-17l72 72a12 12 0 0 1-.02 17.01'/%3E%3C/svg%3E");
    }
  }
}
