@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.DescribeQuestion {
  font-family: $primary_font;
  width: 100%;

  h2 {
    font-family: $secondary_font;
    font-size: $sm_title_font_size;
    margin-bottom: 7px;
  }

  h3 {
    font-family: $secondary_font;
    font-size: $xl_base_font_size;
    margin: 0 0 15px 0;
  }

  form {
    span {
      align-items: flex-end;
      display: flex;
      height: 250px;

      @include breakpoint(tablet-portrait) {
        height: 150px;
      }
    }
  }

  .button{
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
    width: 100%;

    .Button {
      height: 45px;
      width: 252px;
    }
  }
}
