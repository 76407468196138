@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.Contact {
  align-items: center;
  display: flex;
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;

  section {
    background-color: $background_primary_dark_color;
    border: 1px solid $border_color;
    color: $white;
    font-family: Grotesk, sans-serif;
    padding: 30px;

    h2 {
      font-size: pxToRem(25px);
      font-weight: 500;
    }

    h3 {
      font-weight: 500;
    }

    p {
      font-weight: 300;
    }

    div {
      display: flex;
      flex-direction: column;

      a {
        border-bottom: 1px solid grey;
        color: $white;
        cursor: pointer;
        height: max-content;
        text-decoration: inherit;
        width: fit-content;
      }
    }

    .click-contact {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 40px;
      margin-bottom: 10px;
      width: 100%;

      svg {
        margin: 0 8px 0 5px;
        width: 40px;

        &.whatsapp-icon {
          margin: unset;
          width: 50px
        }
      }
    }

    svg {
      color: white;
      margin: 9px 0 0 -7px;
    }
  }
}
