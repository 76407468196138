@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.EntryGenericPage {
  align-items: center;
  background-position: center calc((75% / 2) + 25%);
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: auto;
  min-height: 100vh;
    width: 100%;
  position: relative;
  gap: 50px;

  @include breakpoint(tablet-portrait) {
    padding: 0;
    background-position: left;
  }

  .container-children {
    height: 100vh;
    display: flex;
    background-color: #00067d;
    justify-content: center;
    align-items: center;
    margin: 0;

    @include breakpoint(desktop-small) {
      margin-right: 100px;
    }

    .Card {
      .bg {
        background-color: transparent;
      }

      .Input {
        margin: 10px 0;
      }
      
      label {
        color: white;
        font-family: $roboto_regular;
      }
    }
  }

  .e2w-principles {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 490px;
    height: 100vh;
    margin-left: 100px;

    h2,
    p {
      font-family: $roboto_light;
    }

    h2 {
      font-family: $roboto_regular;
      font-size: pxToRem(40px);
      margin: 50px 0 20px;
    }

    p {
      font-size: pxToRem(20px);
      margin: 0;
    }

    .Logo {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 100px;

      svg {
        height: 124px;
        width: 250px;
      }

      div {
        height: 70px;

        h2 {
          font-size: 2rem;
          line-height: 0.8;
        }
      }
    }
  }
}
