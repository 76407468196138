@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.EmployeeInformation {
  background-color: $background_primary_dark_color;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 39px 0 27px;
  width: 68%;

  h1 {
    color: $white;
    font-family: $secondary_font;
    font-size: $xs_title_font_size;
    margin: 15px 0 30px;
  }

  .checkbox-access {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-right: 60px;
    padding-right: 60px;

    .units-container, .roles-container {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

  }

  .Button {
    align-self: flex-end;
    width: max-content;
  }

  .create-unit {
    margin-top: auto;
    max-width: 290px;

    .Input {
      margin-top: 50px;
    }
  }

  .create-role {
    margin-top: auto;
    max-width: 310px;

    .Input {
      margin-top: 50px;
    }
  }
}
