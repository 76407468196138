@import "../assets/scss/mixins";
@import "../assets/scss/fonts";
@import "../assets/scss/variables";

.App {
  min-height: 100vh;
  position: relative;

  * {
    color: $text_primary_light_color;
    font-family: Roboto, sans-serif;
    font-size: $base_font_size;
    font-weight: normal;
    margin: 0;
  }

  h2, .subtitle {
    font-family: $secondary_font;
    font-size: $sm_title_font_size;
    margin: 0 0 30px 0;
  }

  strong {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .app-bg {
    background-image: url('../assets/images/bg-mobile.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 110%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    @include breakpoint(desktop-small) {
      width: 100%;
    }

    &.color-animation {
      animation: color-change 30s infinite alternate both;

      @keyframes color-change {
        0% {
          filter: hue-rotate(
                          0deg
          );
        }
        33.3% {
          filter: hue-rotate(
                          -46deg
          );
        }
        66.6% {
          filter: hue-rotate(
                          -89deg
          );
        }
        100% {
          filter: hue-rotate(
                          0deg
          );
        }
      }
    }
  }
}
