@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.SaveModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-container {
    background-color: #00057f;
    color: #ffffff;
    padding: 80px 50px 40px 50px;
    box-sizing: border-box;
    width: 500px;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

    .close-button {
      position: absolute;
      top: 10px;
      right: 20px;
      background: none;
      border: none;
      color: #ffffff;
      font-size: 30px;
      cursor: pointer;
    }

    .modal-title {
      margin-bottom: 20px;
      font-size: pxToRem(21px);
      font-family: $roboto_light;
      color: #ffffff;
    }

    .modal-input {
      width: 100%;
      margin: 20px 0;
      padding: 0 0 5px 0;
      border: unset;
      border-bottom: 2px solid #2b35ce;
      background: transparent;
      color: #ffffff;
      font-family: $roboto_light;
      font-size: pxToRem(16px);
      outline: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .save-button {
      background-color: #2b35ce;
      color: #ffffff;
      border: none;
      padding: 20px 50px;
      margin-top: 31px;
      font-size: pxToRem(21px);

      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #0022b6;
      }
    }
  }
}
