$primary-color: #F3A543;
$primary-color-hovered: #BA5600;
$secondary-color: #000AFF;
$secondary-color-hovered: #EDEDED;

$background_primary_dark_color: rgba(29, 29, 48, 0.6);
$background_primary_light_color: #EFEFF2;

$table_header_color: #9FA1AD;

$text_primary_light_color: #EFEFF2;
$text_secondary_color: #8E96AA;
$placeholder_color: #525467;

$border_color: #767E95;
$transparent_state_hover_color: #16489F;
$positive_variation_color: #00FF6C;
$negative_variation_color: #EE4C3D;

$box_shadow: 0 4px 14px 13px rgba(0, 0, 0, 0.05);

$primary_font: Roboto, sans-serif;
$secondary_font: Neue-Montreal, sans-serif;

$roboto_light: Roboto-Light, sans-serif;
$roboto_bold: Roboto-Bold, sans-serif;
$roboto_regular: Roboto-Regular, sans-serif;
$roboto_thin: Roboto-Thin, sans-serif;
$roboto_medium: Roboto-Medium, sans-serif;
$roboto_italic: Roboto-Italic, sans-serif;

$black: #0D0D0D;
$black_light: #343434;
$darkgray: #818181;
$darkgray_topic: #757575;
$gray_input: #BDBDBD;
$green_admin_tag: #3BCB10;
$green_status: #0FCE42;
$green_vibrant: #19E27F;
$gray_register: #707070;
$green_variation: #77BA99;
$green: #B2B216;
$lightgray: #D9D9D9;
$red_background: #FF9191;
$red_status: #FF0000;
$red_variation: #FF0027;
$red_removal: #FF3445;
$orange: #FF6403;
$white: #FFFFFF;
$white_smoke: #F7F7F7;
$yellow: #F8AC00;
$yellow_status: #FFD800;

$xxl_title_font_size: 3.438rem;
$xl_title_font_size: 3.125rem;
$title-size-mobile: 1.3rem;
$lg_title_font_size: 2.5rem;
$title_font_size: 2.375rem;
$sm_title_font_size: 2.375rem;
$xs_title_font_size: 2rem;
$xxs_title_font_size: 1.75rem;
$xxxs_title_font_size: 1.625rem;
$xxl_base_font_size: 1.5rem;
$xl_base_font_size: 1.375rem;
$lg_base_font_size: 1.25rem;
$base_font_size: 1.125rem;
$sm_base_font_size: 1rem;
$xs_base_font_size: 0.875rem;
$xxs_base_font_size: 0.938rem;

$reading_size: 18px;
$subtitle_size: 20px;
$title_size: 32px;

$breakpoints: (
  "mobile-small": 400px,
  "mobile-large": 576px,
  "tablet": 768px,
  "tablet-portrait": 992px,
  "desktop-small": 1200px,
  "desktop-medium": 1400px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px
);

