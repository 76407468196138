@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.PopUpCompany {
  align-items: center;
  background-color: $background_primary_dark_color;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &.active {
    display: flex;
  }

  .container-popup {
    background-color: #00067d;
    // border-bottom: 2px solid $secondary-color;
    // border-top: 2px solid $secondary-color;
    // box-shadow: 0 3px 20px #00000057;
    display: flex;
    position: relative;
    height: 550px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    // padding: 40px;
    box-sizing: border-box;
    min-width: 800px;

    .image-company {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-top: 100px;
    }

    div {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .title {
        text-align: center;
        font-family: $roboto_light;
        font-size: 30px;
        color: white;
      }

      .description {
        text-align: center;
        font-family: $roboto_regular;
        font-size: 16px;
      }
    }

    .button-submit {
      // position: fixed;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 80px;
      min-width: 130px;
      display: flex;
      background-color: #0911b3;
      box-sizing: border-box;
      font-family: $roboto_light;
      font-size: 17px;
      border: none;
      gap: 20px;

      .fa6-solid--arrow-right {
        display: inline-block;
        width: 23px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.PopUp {
  .container-pop-up {
    display: flex;

    .popup-image-company {
      background: url("../../../assets/images/popup-folders.png") no-repeat
        center center;
      height: 405px;
      left: -92px;
      position: relative;
      top: -20px;
      width: 466px;
    }

    .popup-company {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: $white;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(35px);
        font-weight: 600;
        margin: 0;
      }
    }
  }
}
