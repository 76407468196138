@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.TermsOfUse {
  background-color: #00000066;
  border-radius: 20px;
  font-family: $primary_font;
  margin: 20px;
  padding: 50px 15px;
  border-radius: 20px;
  
  @include breakpoint(desktop-small) {
    margin: 50px;
    padding: 50px;
  }

  h1,
  h2,
  h3 {
    font-family: $primary_font;
    font-weight: 700;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 20px;
  }

  .return-button {
    position: fixed;
    left: 75%;
    top: 20px;

    button {
      background-color: #0006ff;
      border: none;
      color: white;
      font-family: $primary_font;
      font-size: 15px;
      padding: 10px;
      border-radius: 9px;
    }

    @include breakpoint(desktop-small) {
      left: 90%;
    }
  }
}
