@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.card-selectcompany {
  align-items: center;
  background-color: $background_primary_dark_color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 253px;
  position: relative;
  width: 253px;

  &:hover {
    border: 1px solid #707070;

    p {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      z-index: 3;
      width: 100%;
    }
  }

  &.no-logo {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 253px;
    justify-content: center;
    width: 253px;

    p {
      display: flex;
    }
  }

  p {
    display: none;
    font-size: $lg_base_font_size;
  }
}
