@import "../../../assets/scss/variables";

.InvestmentsTableRow {
  .Button {
    margin: 0;
  }

  .Grade {
    font-family: $roboto_light;
    font-size: 18px !important;
  }

  .button-details {
    height: 100% !important;
    font-family: $primary_font;
  }

  td {
    
    p {
      align-items: center;
      display: flex;

      svg {
        fill: $border_color;
        margin-right: 5px;
      }
    }

    .Button {
      height: 35px;

      &.secondary-color { 
        font-family: $roboto_light;
        font-size: 14px !important;
        height: min-content;
      }
    }
  }

  &.main {
    margin-bottom: 32px;

    &.active {
      background-color: $transparent_state_hover_color;
    }
  }

  &.description {
    background-color: $transparent_state_hover_color;
    height: 0;
    visibility: hidden;

    &.active {
      height: max-content;
      top: 0;
      visibility: unset;

      td {
        color: $table_header_color;
        display: table-cell;
        font-size: $base_font_size;
        height: unset;
        margin-bottom: 10px;
      }
    }

    td {
      display: none;
    }
  }
}
