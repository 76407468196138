@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.CommunityInfo {
  h2 {
    font-family: $roboto_light;
    font-size: 40px;
    font-weight: 500;
    margin: 50px 0 20px;
  }

  p {
    font-family: $roboto_regular;
    font-size: pxToRem($reading_size);
    margin: 15px 0 30px;
  }

  p.image-error {
    color: $negative_variation_color;
    font-size: $xs_base_font_size;
  }

  .Input {
    margin-bottom: 30px;

    label {
      color: white;
      font-family: $roboto_light;
      font-size: 20px;
    }

    input {
      &:focus {
        border-bottom: 1px solid #767e95;
      }
    }
  }

  .Button {
    background-color: #0005B2;
    border: unset;
    font-family: $roboto_light;
    font-size: 20px;

    &:hover {
      filter: brightness(0.9);
    }

    .ph--arrow-right-bold {
      display: inline-block;
      margin-left: 30px;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='m224.49 136.49l-72 72a12 12 0 0 1-17-17L187 140H40a12 12 0 0 1 0-24h147l-51.49-51.52a12 12 0 0 1 17-17l72 72a12 12 0 0 1-.02 17.01'/%3E%3C/svg%3E");
    }
  }
}

.CompanyLogo {
  margin: 50px 0;
  width: 100%;

  svg {
    height: 71px;
    width: 88px;
  }

  p {
    color: white;
    font-family: $roboto_regular;
    font-size: 20px;
    margin: 0;

    &.upload-button {
      color: rgba(255, 255, 255, 0.50);

      .UploadButton {
        opacity: 100% !important;

        &::before {
          color: white;
        }
      }
    }
  }

  .DragAndDropUpload {
    border: 1px dashed rgba(255, 255, 255, 0.401);
    
    svg {
      path {
        fill: #ffffff;
        opacity: 50%;
      }
    }
  }

  p {
    &.error-message {
      color: $red_status;
    }
  }
}
