@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.TabButton {
  align-items: center;
  border-bottom: 3px solid transparent;
  display: flex;

  button {
    background-color: transparent;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    font-family: $secondary_font;
    font-size: $xs_title_font_size;
    margin-right: unset;
    padding: 10px 0;
    text-align: left;
    text-transform: unset;
  }

  .circle-number {
    align-items: center;
    border: 1px solid $text_secondary_color;
    border-radius: 50%;
    display: flex;
    height: 15px;
    justify-content: center;
    margin-left: 8px;
    min-width: 15px;
    padding: 5px;
    width: auto;

    p {
      color: $text_secondary_color;
      font-size: $xxs_base_font_size;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    border-bottom-color: $text_primary_light_color;
    color: white;
  }
}
