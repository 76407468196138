@import "../../../../assets/scss/variables";

.Select {
  display: flex;
  flex-direction: column;

  & > p {
    color: $red_status;
    font-size: 0.7rem;
    margin: 0 0 5px 0;
    opacity: 0;
    display: none;

    &.visible {
      display: flex;
      opacity: 1;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      font-size: $xxl_base_font_size;
      margin-bottom: 5px;
    }

    & > select {
      appearance: none;
      background-color: transparent;
      background-image: url("../../../../assets/images/select-down-arrow.svg");
      background-position: calc(100% - 5px) center;
      background-repeat: no-repeat;
      background-size: 15px;
      border: 2px solid $border_color;
      border-radius: unset;
      cursor: pointer;
      height: 35px;
      outline: none;
      padding: 0 25px 0 10px;
      -moz-appearance: none;
      -webkit-appearance: none;

      &.disabled {
        background-color: rgba(198, 198, 198, 0.26);
        border-color: transparent;
        color: #6c6c6c;
        cursor: default;
      }

      & > option {
        color: $black;
        cursor: pointer;
      }
    }
  }

  &.label-inline {
    & > div {
      align-items: flex-end;
      flex-direction: row;
    }
  }
}
