@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Investors {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 40px 70px 100px;
  box-sizing: border-box;

  @include breakpoint(desktop-small) {
    margin: 60px 0 0;
    padding: 0 91px 150px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 140px 150px;
  }

  h2.title {
    font-size: 50px;
    font-family: $roboto_light;
  }

  p.investment {
    font-weight: 600;
    font-family: $roboto_light;
    font-size: 20px;
  }

  p.contributions {
    font-size: 20px;
    margin: 10px 0 0 0;
  }

  .investors-cards-container {
    display: grid;
    margin: 30px 0 20px;

    .active-investors {
      margin-right: 20px;
    }

    .Card {
      height: 100%;

      .title {
        font-family: $roboto_light;
        font-size: 15px;
        color: #cccfff;
      }

      .bg {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
      }
    }

    .biggest-investors,
    .smallest-investors {
      margin-left: 0px;
      margin-right: 20px;
    }

    .active-investors-content {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      width: 100%;

      .active-investors-count {
        display: flex;

        span {
          font-size: $lg_base_font_size;
        }

        .active-investors-title {
          font-size: $title_font_size;
          margin-right: 20px;
        }
      }

      > span {
        font-size: $lg_base_font_size;
        text-align: right;
      }
    }

    .biggest-investors-content,
    .smallest-investors-content {
      display: flex;
      flex-direction: column;
      padding: 12px;
      width: 100%;

      .biggest-investors-details,
      .smallest-investors-details {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }

  .charts-container {
    margin: 0;

    .Card {
      height: 100%;

      .title {
        font-family: $roboto_light;
        font-size: 15px;
        color: #cccfff;
      }

      .bg {
        background-color: #3d46db;
        mix-blend-mode: overlay;
        opacity: 0.37;
      }
    }

    .profile {
      display: grid;
      grid-template-columns: 1fr;

      .card-profile-investment,
      .card-profile-contributions {
        margin-right: 20px;

        p {
          margin-top: 50px;
          height: 15px;
          text-align: left;
          width: 100%;
        }

        p.title {
          font-size: $lg_title_font_size;
          margin-top: 0px;
          text-align: left;
        }

        .select-container {
          display: flex;
          width: 100%;

          .Select {
            margin-right: 20px;
            width: 100%;
          }
        }

        .chart {
          align-items: center;
          display: flex;
          justify-content: center;
          margin-left: 30px;
        }
      }
    }
  }

  .investors-search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 60px 0 30px;
    // padding: 30px;

    .investors-count {
      h1 {
        font-size: 30px;
        font-family: $roboto_light;
        margin-bottom: 10px;
      }

      p {
        font-family: $roboto_light;
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }

  .investors-list {
    margin-top: 30px;
    width: 100%;

    .container-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;

      button {
        background: rgba(22, 72, 159, 0.4);
        border: 1px solid rgba(22, 72, 159, 0.4);
        color: white;
        cursor: pointer;
      }
    }

    table.investors-table {
      background-color: transparent;
      width: 100%;

      tbody {
        padding-right: 20px;
      }

      thead {
        margin: 0;

        tr {
          margin: 0 !important;
          background-color: #0006a2;
        }
      }

      tr th,
      tr td {
        padding: 25px 15px 25px 30px;
      }

      tr th {
        font-size: 15px;
        font-family: $roboto_light;
      }

      tr {
        font-size: 13px;
        // background-color: rgba(61,   70, 219, 0.28);
        background-color: #0b16e05a;
        box-sizing: border-box;
        margin-bottom: 15px !important;
      }

      tr th,
      tr td {
        width: 10%;
      }

      tr td button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: $roboto_light;
        padding: 15px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.4s;

        &::before,
        &::after {
          background-color: #3d46db;
          mix-blend-mode: overlay;
          opacity: 0.37;
          pointer-events: none;
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }

        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
}
