@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.Input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  & > svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  & > p {
    color: red;
    font-size: $xs_base_font_size;
    margin: 0 0 5px 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      color: $text_secondary_color;
      font-family: $secondary_font;
      font-size: $lg_base_font_size;
      font-weight: normal;
      margin-bottom: 5px;
    }

    & > input {
      background-color: transparent;
      border: 3px solid transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.50);
      box-sizing: border-box;
      color: $white;
      font-family: $secondary_font;
      font-size: $xxl_base_font_size;
      height: 40px;
      outline: none;
      padding-bottom: 5px;

      &::placeholder {
        color: $placeholder_color;
        font-family: $secondary_font;
        font-size: $xxl_base_font_size;
      }

      &:focus {
        border-bottom: 3px solid $border_color;
      }

      &.disabled {
        background-color: rgba(198, 198, 198, 0.26);
        color: #6c6c6c;

        &::placeholder {
          color: #6c6c6c;
        }
      }
    }

    svg {
      bottom: 10px;
      position: absolute;
      right: 15px;
      width: 20px;
    }
  }

  &.invalid {
    border-color: red;
  }

  .detail {
    color: $table_header_color;
    font-size: $lg_base_font_size;
    margin-top: 15px;
    opacity: unset;
  }
}
