@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.AbilitiesAnalysis {
  margin-top: 100px;

  h2 {
    font-family: $roboto_light;
    font-size: 32px;
  }

  .subtitle {
    font-family: $roboto_light;
    font-size: 18px;
  }

  .Card {
    margin-top: 20px;
    min-height: 808px;

    .bg {
      background-color: #080c48;
      mix-blend-mode: overlay;
      // opacity: 0.60;
    }

    .content {
      align-items: flex-start;

      .filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          font-family: $roboto_light;
          font-size: $xxs_title_font_size;
          white-space: nowrap;
          margin-right: 40px;
        }

        .select-container {
          display: grid;
          grid-template-columns: 350px 350px;
          margin-top: 15px;

          .Select {
            margin: 10px 30px;

            &:first-child {
              margin: 10px 30px 0 0;
            }

            select {
              height: 48px;
              border-color: #7376b7;
              border-width: 1px;
              font-family: $roboto_light;
              font-size: 17px;
              opacity: 1;
              color: white;
            }
          }
        }
      }

      .chart-container {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        width: 100%;

        table {
          margin-top: -5px;
        }

        .line-chart {
          width: 50%;
          position: relative;

          .title-chart {
            // right: 80%;
            left: 5%;
            color: #cccfff;
            font-family: $roboto_light;
            position: absolute;
            font-size: 24px;

            span {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
