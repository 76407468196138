@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.AcceptInvite {
  align-items: center;
  background-color: $black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  h2 {
    color: $white;
    font-family: Grotesk, sans-serif;
    margin: 0;
  }

  .card-selectcompany {
    align-items: center;
    background-color: $black;
    background: no-repeat;
    background-size: cover;
    border: 1px solid #707070;
    display: flex;
    height: 253px;
    justify-content: center;
    margin: 30px 0;
    width: 253px;

    &:hover {
      border: 2px solid #FFEB0086;
      box-shadow: 4px 5px 20px #FFEB0086;
      transition: box-shadow 600ms;
    }
  }
}
