@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

input {
  &.UploadButton {
    height: 25px;
    width: 175px;
  }

  &.UploadButton::-webkit-file-upload-button {
    border: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
    width: 200px;
  }

  &.UploadButton::before {
    align-items: center;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    content: "Escolha um arquivo";
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: $roboto_light;
    font-size: pxToRem(20px);
    font-weight: 500;
    justify-content: center;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
  }

  &.UploadButton::before:focus {
    border-bottom-width: 4px;
  }
}
