@import "../../../../assets/scss/variables";

.Textarea {
  width: 100%;

  span {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $border_color;
    border-radius: unset;
    box-sizing: border-box;
    display: block;
    font-family: $secondary_font;
    font-size: $lg_base_font_size;
    height: calc(100% - 53px);
    outline: none;
    padding: 10px;
    resize: none;
    width: 100%;
    word-break: break-word;
    -webkit-appearance: none;

    &:after {
      content: attr(data-name);
      color: $placeholder_color;
      font-family: $secondary_font;
      font-size: $xs_title_font_size;
      margin: 0;
      text-align: left;
    }
  }

  & > p {
    color: red;
    font-size: 0.8rem;
    height: 15px;
    margin: 0 0 5px 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  label {
    font-family: $secondary_font;
    font-size: $title-size-mobile;
  }

  .detail {
    color: $table_header_color;
    font-size: $lg_base_font_size;
    margin-top: 15px;
    opacity: unset;
  }
}
