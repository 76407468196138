@import "../../assets/scss/fonts";
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.NotFoundPage {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 30px;

  @include breakpoint(tablet-portrait) {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 0 ;
    width: 100%;
  }

  .notfound-container {
    align-items: center;
    background: $background_primary_dark_color;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include breakpoint(tablet-portrait) {
      flex-direction: row;
      width: 45%;
    }

    & > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 10px;

      &:nth-child(2) {
        margin-top: 25px;
        width: 100%;

        .button-notfound {
          align-items: center;
          display: flex;
          width: 100%;
        }
      }

      @include breakpoint(tablet-portrait) {
        padding: 50px;
        }

      & > h2 {
        font-family: $secondary_font;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.71px;
        margin: 30px 0;
      }
    }
  }

  p {
    color: $text_primary_light_color;
    font-family: $secondary_font;
    font-size: $xs_title_font_size;
    letter-spacing: 0.011rem;
    line-height: 1.75rem;
    margin: 5px 0;

    &:nth-child(2) {
      font-size: $xl_base_font_size;
      margin-top: 10px;
    }

    @include breakpoint(tablet-portrait) {
      text-align: justify;
    }

    span {
      font-weight: 800;
    }

    a {
      color: $text_primary_light_color;
    }
  }
}
