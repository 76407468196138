@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.chart {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  position: relative;

  .error-message {
    align-items: center;
    display: flex;
    height: 350px;
    justify-content: center;
    text-align: center;

    span {
      font-family: $secondary_font;
      font-size: $xxl_base_font_size;
    }
  }

  svg {
    height: 350px;
    overflow: visible;
    width: 345px;
  }

  text {
    font-family: $secondary_font;
    font-size: 0.6rem;

    &:nth-child(4) {
      color: $primary-color;
      font-size: 0.5rem;
    }

    &.chart-title {
      font-family: $secondary_font;
      font-size: 0.6rem;
      text-align: center;
    }
  }

  tspan {
    font-family: $secondary_font;
    font-size: 0.5rem;
  }
}
